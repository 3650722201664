import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  ActionList,
  Badge,
  Button,
  Card,
  Form,
  Icon,
  Modal,
  Popover,
  TextField,
  TextStyle,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import {
  AddNoteMajor,
  CircleInformationMajor,
  DeleteMajor,
  MobileVerticalDotsMajor,
} from "@shopify/polaris-icons";
import { Row, Col, Space, Divider } from "antd";
import { formatTo2Digits, NumberFormat } from "../../utils/Formats";
import { getProductValidations } from "../../utils/orderValidations";
import { getImageVideoUrl } from "../../utils/productValidators";

export default function ArticuloItem({
  quantity,
  handleChange = () => {},
  onRemove = () => {},
  disabled = false,
  selectNote = () => {},
  articulo,
  descPromoAplicada = [],
  runOnInsertBegin = () => {},
  descVolumenAplicada = [],
  descMaximos = [],
  showAsDetail = false,
  showTax = false,
  mode = "fit",
  isLast = false,
  setQuantityError = () => {},
  clientCurrency = {
    id: 1,
    nombre: "Moneda nacional",
    claveFiscal: "MXN",
    simbolo: "$",
    tipoCambio: 1,
  },
  currencies = [],
  discounts = [],
  priceToUse = null,
  isQuotation = false,
}) {
  const minimum = useRef(articulo.minimum ? articulo.minimum : 1);
  const factorVenta = useRef(articulo.factor_venta ? articulo.factor_venta : 1);
  const quantityRef = useRef(null);
  const runOnInsertBeginRef = useRef(null);
  const [active, setActive] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const [note, setNote] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [articlePrice, setArticlePrice] = useState(
    articulo.precios ? articulo.precios[0]?.precio : 0
  );
  const [quantityAdded, setQuantityAdded] = useState(1);
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [fieldsError, setFieldsError] = useState(null);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleActiveModal = useCallback(
    () => setActiveModal((active) => !active),
    []
  );
  const currency = currencies.find(
    ({ label }) => label === clientCurrency.claveFiscal
  );

  let precioEmpresa =
    articulo.precios.find(
      ({ precio_empresa_id }) => precio_empresa_id === priceToUse
    ) ||
    articulo.precios.find(({ precio_empresa_id }) => precio_empresa_id === 42);

  const getCurrencyConvertion = useCallback(
    (price) => {
      if (precioEmpresa) {
        const moneda = precioEmpresa?.moneda;
        const tipoCambio = currency?.tipoCambio;

        if (moneda) {
          if (clientCurrency.claveFiscal === "MXN") {
            return price * tipoCambio;
          } else if (moneda === "MXN") {
            return price / tipoCambio;
          } else {
            return price;
          }
        }
        return 0;
      }
      return 0;
    },
    [clientCurrency.claveFiscal, currency?.tipoCambio, precioEmpresa]
  );

  useEffect(() => {
    quantityRef.current = quantity;
    runOnInsertBeginRef.current = runOnInsertBegin;
  });

  const quantityValidators = useCallback(
    (value) => {
      if (value < minimum.current) {
        setFieldsError(`Mínimo ${minimum.current}`);
        setQuantityError(true, articulo._id);
        return;
      }

      if (value > articulo.maximum) {
        if (articulo.maximum && articulo.maximum > 0) {
          setFieldsError(`Máximo ${articulo.maximum}`);
          setQuantityError(true, articulo._id);
        } else {
          setFieldsError("");
          setQuantityError(false, articulo._id);
        }
        if (value % factorVenta.current !== 0) {
          setFieldsError(`Incrementos de ${factorVenta.current}`);
          setQuantityError(true, articulo._id);
        }
        return;
      }

      if (value % factorVenta.current !== 0) {
        setFieldsError(`Incrementos de ${factorVenta.current}`);
        setQuantityError(true, articulo._id);
        return;
      }

      setFieldsError("");
      setQuantityError(false, articulo._id);
    },
    [articulo._id, articulo.maximum, setQuantityError]
  );

  useEffect(() => {
    if (articulo.notas) {
      setNote(articulo.notas);
    }
    if (!showAsDetail) {
      const { percentDefault, price } = getProductValidations({
        product: articulo,
        descMaximos,
        descPromoAplicada,
        descVolumenAplicada,
        discounts,
        priceToUse,
        quantity,
      });

      setPercentage(percentDefault);
      setArticlePrice(price);

      // Valida si el articulo tiene un mínimo y un factor de venta
      if (
        !articulo.minimum ||
        (articulo.minimum === 1 && articulo?.factor_venta === 1)
      ) {
        setTooltipVisible(false);
      }

      function calculateFinalPrice() {
        // Valida que exista un porcentaje por default, si no existe dejará el precio sin aplicar el descuento;
        let discount =
          percentDefault > 0 ? price - price * (percentDefault / 100) : price;

        let fPriceWithDiscount = getCurrencyConvertion(discount);

        return fPriceWithDiscount;
      }

      let totalCalculated =
        Math.round(
          (quantityRef.current * calculateFinalPrice() + Number.EPSILON) * 100
        ) / 100;

      runOnInsertBeginRef.current(totalCalculated, articulo._id);
    }

    const quantityAdded = quantity === 1 ? String(minimum.current) : quantity;
    setQuantityAdded(quantityAdded);
    quantityValidators(quantityAdded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    articulo,
    descMaximos,
    descPromoAplicada,
    descVolumenAplicada,
    showAsDetail,
    quantity,
    getCurrencyConvertion,
  ]);

  function addNote() {
    toggleActiveModal();
    selectNote(note, articulo._id);
    setNote(note);
  }

  function getDiscount() {
    // Valida que exista un porcentaje por default, si no existe dejará el precio sin aplicar el descuento;
    let discount =
      percentage > 0
        ? articlePrice - articlePrice * (percentage / 100)
        : articlePrice;

    return discount;
  }

  function handleFieldChange(value) {
    if (value === "0") {
      let totalPrice =
        Math.round((Number("1") * getDiscount() + Number.EPSILON) * 100) / 100;
      totalPrice = getCurrencyConvertion(totalPrice);
      value = "1";
      // Change this
      handleChange("1", articulo._id, totalPrice);
    } else {
      // Change this
      let totalPrice =
        Math.round((Number(value) * getDiscount() + Number.EPSILON) * 100) /
        100;
      totalPrice = getCurrencyConvertion(totalPrice);
      handleChange(value, articulo._id, totalPrice);
    }
    if (!showAsDetail) {
      setQuantityAdded(value);
      quantityValidators(value);
    }
  }

  function validateFinalPrice() {
    let price = showAsDetail ? articulo.totalPrecioConDescuento : getDiscount();
    let impuestos = articulo.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    // if (summary === 0.16) {
    //   summary = 0;
    // }
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    // Convert the price depending client currency and item currency
    price = showAsDetail ? price : getCurrencyConvertion(price);

    return (
      Math.round(((price || 0) * quantityAdded + Number.EPSILON) * 100) / 100
    );
  }

  function validatePriceWithTax() {
    let price = showAsDetail ? articulo.totalPrecioConDescuento : getDiscount();
    let impuestos = articulo.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    // if (summary === 0.16) {
    //   summary = 0;
    // }
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    // Convert the price depending client currency and item currency
    price = showAsDetail ? price : getCurrencyConvertion(price);
    price = Math.round((price + Number.EPSILON) * 100) / 100;

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function validatePriceWithTaxMain() {
    let price = showAsDetail ? articulo.precioDeLista.precio : articlePrice;
    let impuestos = articulo.impuestos?.filter((j) => j.porcentaje > 0) || [];
    let summary = 0;
    impuestos.forEach((j) => (summary = summary + 1 * (j.porcentaje / 100)));
    const tax = 1 + summary;
    price = showTax ? price * tax : price;
    price = Math.round((price + Number.EPSILON) * 100) / 100;

    return Number(price || 0).toLocaleString("en", NumberFormat);
  }

  function onBlurTextField(value) {
    if (!showAsDetail) {
      quantityValidators(value);
    }

    const existencia = articulo.existencia.reduce(
      (prev, current) => prev + current.existencia,
      0
    );

    if (value <= 0) {
      let totalPrice =
        Math.round((Number(1) * getDiscount() + Number.EPSILON) * 100) / 100;
      totalPrice = getCurrencyConvertion(totalPrice);
      // Change this
      const min = articulo.minimum || 1;
      handleChange(min, articulo._id, totalPrice);
      return;
    }

    if (isQuotation) {
      let totalPrice =
        Math.round((Number(value) * getDiscount() + Number.EPSILON) * 100) /
        100;
      totalPrice = getCurrencyConvertion(totalPrice);
      // Change this
      handleChange(value, articulo._id, totalPrice);
      return;
    } else {
      if (
        articulo.maximum &&
        articulo.maximum > 0 &&
        value > articulo.maximum
      ) {
        let totalPrice =
          Math.round((Number(1) * getDiscount() + Number.EPSILON) * 100) / 100;
        totalPrice = getCurrencyConvertion(totalPrice);
        // Change this
        handleChange(articulo.maximum, articulo._id, totalPrice);
        return;
      }
    }

    if (existencia > 0 && !articulo.continuar_vendiendo) {
      if (Number(quantityAdded) > existencia) {
        let totalPrice =
          Math.round(
            (Number(existencia) * getDiscount() + Number.EPSILON) * 100
          ) / 100;
        totalPrice = getCurrencyConvertion(totalPrice);

        // Change this
        handleChange(existencia, articulo._id, totalPrice);
      }
      return;
    }
  }

  const activator = (
    <Button icon={MobileVerticalDotsMajor} plain onClick={toggleActive} />
  );

  const addNoteModal = activeModal ? (
    <Modal
      title="Agregar nota"
      open={activeModal}
      onClose={toggleActiveModal}
      primaryAction={{ content: "Agregar", onAction: addNote }}
      secondaryActions={[{ content: "Cancelar", onAction: toggleActiveModal }]}
    >
      <Modal.Section>
        <Card.Section>
          <Form onSubmit={addNote}>
            <TextField
              focused={true}
              placeholder={note ? "Editar nota" : "Agregar nota"}
              value={note}
              onChange={(val) => setNote(val)}
            />
          </Form>
        </Card.Section>
      </Modal.Section>
    </Modal>
  ) : null;

  const infoSection = (
    <div className="flex mt-6 items-start">
      <div className="relative mr-8">
        <Thumbnail
          size="large"
          source={
            getImageVideoUrl(articulo?.imagen, "image", false) ||
            "/Default Photo.png"
          }
        />
        <div className="absolute custom-badge z-20">
          <Badge>{String(quantityAdded)}</Badge>
        </div>
      </div>
      <div className="flex flex-col">
        <p className="text-blue-600">{articulo ? articulo.nombre : ""}</p>
        <TextStyle variation="subdued">
          SKU: {articulo ? articulo.clave : ""}
        </TextStyle>
      </div>
    </div>
  );

  const popoverActions = (
    <Popover
      active={active}
      activator={activator}
      activatorWrapper="div"
      fluidContent
    >
      <ActionList
        items={[
          {
            content: articulo.notas ? "Editar nota" : "Agregar nota",
            onAction: () => {
              toggleActiveModal();
              toggleActive();
            },
            icon: AddNoteMajor,
          },
          {
            content: "Eliminar",
            onAction: () => {
              onRemove(articulo._id);
              toggleActive();
            },
            icon: DeleteMajor,
            destructive: true,
          },
        ]}
      />
    </Popover>
  );

  const priceWithTaxMain = (
    <TextStyle variation="subdued">${validatePriceWithTaxMain()}</TextStyle>
  );

  const percentageDiscount = (
    <TextStyle variation="subdued">{`(${
      showAsDetail ? articulo.totalPorcentajeDescuento : percentage
    })%`}</TextStyle>
  );

  const originalPrice = articulo &&
    (showAsDetail ? articulo.totalPorcentajeDescuento > 0 : percentage > 0) && (
      <div className="flex justify-end">
        <div className="mr-6 line-through">{priceWithTaxMain}</div>
        <div className="sm:mb-4	mb-4	">{percentageDiscount}</div>
      </div>
    );

  const textField = !showAsDetail ? (
    <div className="">
      <TextField
        disabled={disabled}
        type="number"
        min={1}
        max={
          isQuotation
            ? articulo.maximum && articulo.maximum > 0
              ? articulo.maximum
              : undefined
            : articulo?.continuar_vendiendo ||
              (articulo.maximum && articulo.maximum === 0) ||
              !articulo.maximum
            ? undefined
            : articulo?.existencia
                ?.filter((exis) => exis.existencia > 0)
                .reduce((prev, current) => prev + current.existencia, 0)
        }
        value={String(quantityAdded)}
        onChange={handleFieldChange}
        onBlur={() => onBlurTextField(Number(quantityAdded))}
        suffix={
          <div className="flex items-center">
            {articulo ? articulo?.unidadmed : ""}
            {tooltipVisible && (
              <div className="tooltip-space cursor-pointer">
                <Tooltip
                  content={
                    <>
                      <p>Mínimo: {minimum.current}</p>
                      <p>
                        Máximo:{" "}
                        {articulo.maximum === 0
                          ? "Ilimitado"
                          : articulo.maximum}
                      </p>
                      <p>Factor: {factorVenta.current}</p>
                    </>
                  }
                  preferredPosition="mostSpace"
                >
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            )}
          </div>
        }
        error={fieldsError}
      />
    </div>
  ) : (
    <TextField
      disabled={disabled}
      type="number"
      min={1}
      value={String(quantityAdded)}
      onChange={handleFieldChange}
      onBlur={onBlurTextField}
      suffix={articulo ? articulo.unidadmed : ""}
    />
  );

  const priceWithTaxText = <TextStyle>${validatePriceWithTax()}</TextStyle>;

  // FIX
  const finalPriceText = (
    <TextStyle variation="strong">
      {formatTo2Digits(validateFinalPrice(), true)}
    </TextStyle>
  );

  const notes = articulo.notas ? (
    <TextStyle variation="negative">{articulo.notas}</TextStyle>
  ) : null;

  return (
    <>
      {addNoteModal}
      {mode === "shopping" ? (
        <>
          <Row align="middle" gutter={[0, 12]}>
            <Col span={22} className>
              <Row align="middle">
                <Col span={24}>{infoSection}</Col>
              </Row>
            </Col>
            <Col span={2} className="flex justify-end">
              {popoverActions}
            </Col>
            <Col span={8} className="">
              {textField}
            </Col>
            <Col span={10} className="">
              <Row align="top" className="pl-4">
                {percentage > 0 && (
                  <Col span={24} className="">
                    <Space size="middle">
                      <div className="line-through">{priceWithTaxMain}</div>
                      {percentageDiscount}
                    </Space>
                  </Col>
                )}
                <Col span={24}>
                  <Space size="middle">X{finalPriceText}</Space>
                </Col>
              </Row>
            </Col>
            <Col span={6} className="flex justify-end">
              {priceWithTaxText}
            </Col>
            <Col span={24} className="flex justify-end">
              {notes}
            </Col>
          </Row>
          <Divider className="my-8" />
        </>
      ) : (
        <div
          className={`mb-2 pb-4 ${!isLast ? "border-b border-gray-300" : ""} `}
        >
          <Row gutter={[1, 24]} align="middle">
            <Col span={9} xs={24} sm={12} md={10}>
              {infoSection}
            </Col>
            <Col span={9} xs={18} sm={6} md={7}>
              <div
                className="flex flex-row h-full justify-end items-center mx-2 flex-row-reverse 
            xs:flex-col-reverse flex-col-reverse inline-flex w-min mx:flex-row sm:flex-col flex-col-reverse 
            flex-col-reverse inline-flex w-min md:flex-col lg:flex-col xl:flex-row flex-wrap"
              >
                <div className="flex flex-col mx-4 justify-end xs:mx-4 pt-4	mx-4 sm:mx-4 md:mx-4">
                  {/* TEXTO QUE INDICA EL PRECIO ORIGINAL DEL PRODUCTO PERO SUBRAYADO */}
                  {originalPrice}
                </div>
                <div className="custom-textfield-numeric xs:relative relative contents">
                  {textField}
                </div>
              </div>
            </Col>
            <Col span={6} xs={6} sm={6} md={7}>
              <div className="flex flex-row h-full justify-end items-center xs:items-start">
                <div className="flex flex-col justify-end items-center xs:self-start">
                  <div className="flex flex-row-reverse xs:justify-end justify-end sm:flex-row-reverse mx:flex-row lg:flex-row flex-row-reverse xl:flex-row flex-row-reverse">
                    <div className="mr-2">{priceWithTaxText}</div>
                    <TextStyle>×</TextStyle>
                  </div>
                  <div className="mx-4">{finalPriceText}</div>
                </div>
                {!disabled && (
                  <div className="mr-2 xs:self-start">{popoverActions}</div>
                )}
              </div>
            </Col>
          </Row>
          <div className="flex justify-end mb-3">{notes}</div>
        </div>
      )}
    </>
  );
}
