import React, { useState, useCallback } from "react";
import {
  Avatar,
  Filters,
  ChoiceList,
  EmptyState,
  TextStyle,
  Checkbox,
  Card,
  ResourceList,
  TextField,
  Spinner,
  ResourceItem,
} from "@shopify/polaris";
import { useHistory } from "react-router-dom";
import "./ComercioClientesList.css";
import ClienteListItem from "../ClienteListItem/ClienteListItem";
import { changePage } from "../../actions/ClientesActions";
import { useDispatch } from "react-redux";
import { AntPagination, SkeletonTable } from "..";
import { Table } from "antd";
import { invitarClienteComercio } from "../../actions/ComerciosActions";
import { toggleToast } from "../../actions/InteractiveActions";
import AssignStock from "../AssignStockModal";

export default function ComercioClientesList({
  clientes,
  isLoading,
  accountStatus,
  queryValue,
  handleFiltersQueryChange,
  handleQueryValueRemove,
  orderSelected,
  handleOrderSelectedChange,
  orden,
  handleOrdenChange,
  handleAccountStatusRemove,
  handleAccountStatusChange,
  pagination,
  taggedWith,
  handleTaggedWithChange,
  handleTaggedWithRemove,
}) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortBy, setSortyBy] = useState("hoy");
  const [isInviting, setIsInviting] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleSortByRemove = useCallback(() => setSortyBy("hoy"), []);
  const handleModalChange = useCallback(
    () => setIsOpenModal(!isOpenModal),
    [isOpenModal]
  );

  const handleFiltersClearAll = useCallback(() => {
    handleSortByRemove();
    handleAccountStatusRemove();
    handleQueryValueRemove();
    handleTaggedWithRemove();
  }, [
    handleSortByRemove,
    handleQueryValueRemove,
    handleAccountStatusRemove,
    handleTaggedWithRemove,
  ]);

  function handleSelect(selected, val) {
    if (selected) {
      setSelectedItems((state) => [...new Set(state.concat(val))]);
    } else {
      setSelectedItems(selectedItems.filter((sel) => val !== sel));
    }
  }

  function handleSelectAll(values = []) {
    setSelectedItems(values);
  }

  function invitarCliente() {
    const clienteToInvite = clientes
      .filter(
        (cliente) =>
          selectedItems.includes(cliente._id) &&
          [2, 3].includes(cliente.estatus.tipo) &&
          cliente.email
      )
      .map(({ _id }) => _id);
    if (clienteToInvite.length > 0) {
      setIsInviting(true);
      dispatch(invitarClienteComercio(clienteToInvite))
        .then((res) => {
          if (res.ok) {
            dispatch(toggleToast({ message: res.message }));
          }
        })
        .catch((error) => {
          if (error.response && !error.response?.data?.ok) {
            dispatch(toggleToast({ message: error.response.data.message }));
          }
        })
        .finally(() => setIsInviting(false));
    }
  }

  let appliedFilters = [];
  if (!isEmpty(sortBy)) {
    const key = "ordenar";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, sortBy),
      onRemove: handleSortByRemove,
    });
  }

  if (!isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }

  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  function navigate(id) {
    router.push(`/admin/clientes/${id}`);
  }

  const emptyStateMarkup =
    clientes.length === 0 && !isLoading ? (
      <EmptyState
        heading="Tus clientes aparecerán aquí"
        image="/empty-customers.png"
      >
        <p>
          Después de que te conectes con Microsip, aquí se mostrarán tus
          clientes, luego asígnale su almacén y envíales invitación de acceso.
        </p>
      </EmptyState>
    ) : undefined;

  const filters = [
    {
      key: "accountStatus",
      label: "Estatus de cliente",
      filter: (
        <ChoiceList
          title="Estatus de cliente"
          titleHidden
          choices={[
            { label: "Activos", value: "Activo" },
            { label: "Inactivos", value: "Inactivo" },
            { label: "Invitados", value: "Invitado" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Etiquetado con",
      filter: (
        <TextField
          label="Etiquetado con"
          value={taggedWith}
          onChange={handleTaggedWithChange}
        />
      ),
      shortcut: true,
    },
    {
      key: "orderSelected",
      label: "Ordenar",
      filter: (
        <div className="flex flex-col">
          <ChoiceList
            title="Ordenar"
            titleHidden
            choices={[
              { label: "Nombre", value: "nombre" },
              { label: "Email", value: "email" },
              { label: "Estatus", value: "estatus" },
            ]}
            selected={orderSelected || []}
            onChange={handleOrderSelectedChange}
          />
          <div className="flex w-full border border-t-1 border-b-0 border-r-0 border-l-0 border-gray-400">
            <ChoiceList
              title="Ordenar"
              titleHidden
              choices={[
                { label: "Ascendente", value: "1" },
                { label: "Descendente", value: "-1" },
              ]}
              selected={orden}
              onChange={handleOrdenChange}
            />
          </div>
        </div>
      ),
      shortcut: true,
    },
  ];
  return (
    <div>
      <Card>
        <Card.Section>
          <Filters
            queryPlaceholder="Buscar cliente"
            queryValue={queryValue}
            onQueryChange={handleFiltersQueryChange}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleFiltersClearAll}
            filters={filters}
            appliedFilters={appliedFilters}
          />
        </Card.Section>
        {isOpenModal && (
          <AssignStock
            isOpen={isOpenModal}
            handleChange={handleModalChange}
            clientes={selectedItems}
            handleSelectAll={handleSelectAll}
          />
        )}
        {isLoading ? (
          <SkeletonTable hasCheckbox headingsCount={4} rowsCount={5} />
        ) : (
          <div>
            <div className="selectable-items">
              <ResourceList
                totalItemsCount={pagination.total}
                resourceName={{ singular: "cliente", plural: "clientes" }}
                items={clientes}
                selectable
                selectedItems={selectedItems}
                onSelectionChange={handleSelectAll}
                renderItem={() => <ResourceItem />}
                promotedBulkActions={[
                  {
                    content: (
                      <div className="flex flex-row items-center">
                        {isInviting && (
                          <div className="mr-4">
                            <Spinner size="small" color="teal" />
                          </div>
                        )}
                        <TextStyle>Invitar</TextStyle>
                      </div>
                    ),
                    onAction: invitarCliente,
                    disabled: isInviting,
                  },
                ]}
                bulkActions={[
                  {
                    content: "Asignar almacén",
                    onAction: handleModalChange,
                  },
                ]}
              />
            </div>
            <div className="ant-table-styles">
              <Table
                onRow={(record) => ({
                  onClick: () =>
                    handleSelect(
                      !Boolean(selectedItems.indexOf(record.id) >= 0),
                      record.id
                    ),
                })}
                rowKey="id"
                dataSource={clientes}
                scroll={{ scrollToFirstRowOnChange: true, x: "100%" }}
                pagination={false}
                locale={{ emptyText: emptyStateMarkup }}
                columns={[
                  {
                    title: "Nombre",
                    dataIndex: "nombre",
                    render: (nombre, record) => (
                      <div className="flex flex-row items-center">
                        <Checkbox
                          value={record._id}
                          checked={selectedItems.indexOf(record._id) >= 0}
                          onChange={(val) => handleSelect(val, record._id)}
                        />
                        <div className="flex flex-row items-center">
                          <div className="mr-8">
                            <Avatar
                              customer={false}
                              size="medium"
                              name={nombre}
                              initials={nombre
                                .split(" ")
                                .slice(0, 2)
                                .map((n) => (n[0] ? n[0].toUpperCase() : ""))
                                .join("")}
                            />
                          </div>
                          <div
                            className="flex flex-col"
                            onClick={() => navigate(record._id)}
                          >
                            <div className="text-link">
                              <TextStyle variation="strong">{nombre}</TextStyle>
                            </div>
                            <TextStyle variation="subdued">
                              {record.email}
                            </TextStyle>
                          </div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Ciudad",
                    dataIndex: "direcciones",
                    render: (direcciones, { ciudad }) => (
                      <TextStyle>
                        {direcciones && direcciones.length > 0
                          ? direcciones[0]["ciudad"]
                          : ciudad
                          ? ciudad
                          : "--"}
                      </TextStyle>
                    ),
                  },
                  {
                    title: "Estatus",
                    dataIndex: "estatus",
                    render: (estatus) => (
                      <ClienteListItem status={estatus.nombre} />
                    ),
                  },
                  {
                    title: "Almacen",
                    dataIndex: "almacen",
                    render: (almacen) => (
                      <TextStyle>
                        {almacen
                          ? almacen.nombre
                            ? almacen.nombre
                            : "--"
                          : "--"}
                      </TextStyle>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
        <div className="flex justify-center py-6 border pagination px-10">
          <AntPagination
            total={pagination.total}
            current={pagination.currentPage + 1}
            pageSize={pagination.sizePerPage}
            onChange={(page) => {
              dispatch(changePage(page - 1));
            }}
          />
        </div>
      </Card>
    </div>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "ordenar":
        return `Ordenar por ${value}`;
      case "accountStatus":
        return value.map((val) => `Cliente ${val}`).join(", ");
      case "taggedWith":
        return `Etiquetado con ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null || value === "hoy";
    }
  }
}
