import _ from "lodash";

export const getShippingFees = (shippingFees = [], currency) => {
  if (!shippingFees) {
    return [];
  }
  return shippingFees.map(({ price, maxPrice, minPrice, ...rest }) => ({
    ...rest,
    price:
      currency?.claveFiscal === "MXN" ? price : price / currency?.tipoCambio,
    minPrice:
      currency?.claveFiscal === "MXN"
        ? minPrice
        : minPrice / currency?.tipoCambio,
    maxPrice:
      currency?.claveFiscal === "MXN"
        ? maxPrice
        : maxPrice / currency?.tipoCambio,
  }));
};
const getDiscountAppliedToCustomer = (discounts = [], customer) => {
  let discountsCustomer = discounts
    .filter(({ clientes = [] }) =>
      clientes.some(({ clienteId }) => clienteId === customer._id)
    )
    .map((discount) => ({
      ...discount,
      clientes: discount.clientes.filter(
        ({ clienteId }) => clienteId === customer._id
      ),
    }));

  let discountsType = discounts
    .filter(({ tiposCliente = [] }) =>
      tiposCliente.some(
        ({ tipoClienteId: { id } }) =>
          id === customer.tipo_cliente.tipo_cliente_id
      )
    )
    .map((discount) => ({
      ...discount,
      tiposCliente: discount.tiposCliente.filter(
        ({ tipoClienteId: { id } }) =>
          id === customer.tipo_cliente.tipo_cliente_id
      ),
    }));

  let discountsZone = discounts
    .filter(({ zonasCliente = [] }) =>
      zonasCliente.some(
        ({ zonaClienteId: { id } }) =>
          id === customer.zona_cliente.zona_cliente_id
      )
    )
    .map((discount) => ({
      ...discount,
      zonasCliente: discount.zonasCliente.filter(
        ({ zonaClienteId: { id } }) =>
          id === customer.zona_cliente.zona_cliente_id
      ),
    }));

  let discountIds = [];
  let priceToUse = undefined;
  if (discountsCustomer.length > 0) {
    priceToUse = discountsCustomer.sort((a, b) => b.id - a.id)[0].clientes[0]
      .precioListaId.id;
  } else if (discountsType.length > 0) {
    priceToUse = discountsType.sort((a, b) => b.id - a.id)[0].tiposCliente[0]
      .precioListaId.id;
  } else if (discountsZone.length > 0) {
    priceToUse = discountsZone.sort((a, b) => b.id - a.id)[0].zonasCliente[0]
      .precioListaId.id;
  }
  if (discountsCustomer.length > 0) {
    discountsCustomer.forEach(({ clientes }) =>
      clientes.forEach(({ descuentoId }) => discountIds.push(descuentoId))
    );
  }
  if (discountsType.length > 0) {
    discountsType.forEach(({ tiposCliente }) =>
      tiposCliente.forEach(({ descuentoId }) => discountIds.push(descuentoId))
    );
  }
  if (discountsZone.length > 0) {
    discountsZone.forEach(({ zonasCliente }) =>
      zonasCliente.forEach(({ descuentoId }) => discountIds.push(descuentoId))
    );
  }

  if (
    discountsCustomer.length === 0 &&
    discountsType.length === 0 &&
    discountsZone.length === 0
  ) {
    let discount = discounts
      ?.filter((d) => d.precioListaId)
      .sort((a, b) => b.id - a.id);
    if (discount.length > 0) {
      priceToUse = discount[0]?.precioListaId.id;
    }
    discounts.forEach(({ descuento }) => discountIds.push(descuento));
  }

  discountIds = [...new Set(discountIds)];
  return {
    priceToUse,
    discountIds,
  };
};

export const getDiscounts = (discounts = [], customer) => {
  // Filtra descuentos por tipo
  const filterByType = (tipo) => discounts.filter((i) => i.tipo.tipo === tipo);

  const { discountIds, priceToUse } = getDiscountAppliedToCustomer(
    filterByType(1),
    customer
  );

  const productsDiscount = filterByType(2).filter(({ _id }) =>
    discountIds.includes(_id)
  );

  return {
    promos: filterByType(3),
    volumenes: filterByType(4),
    maximos: filterByType(5),
    productsDiscount,
    priceToUse,
  };
};

const calculateCumulativeDiscount = (porcentages = []) => {
  porcentages = porcentages.filter((percentage) => percentage > 0);
  let acc = 0;

  for (let i = 0; i < porcentages.length; i++) {
    const porcentage = porcentages[i];
    acc += porcentage - acc * (porcentage / 100);
  }
  return Number(acc.toFixed(2));
};

const validatePercentProduct = (discounts = [], product) => {
  let accDiscounts = [];

  let discountsByProductIds = [];
  let discountsByProduct = discounts
    .filter(({ articulos = [] }) =>
      articulos.some(({ articulo }) => articulo === product._id)
    )
    .map(({ articulos = [], _id }) => ({
      _id,
      articulos: articulos.filter(({ articulo }) => articulo === product._id),
    }))
    .map(({ _id, articulos }) => {
      discountsByProductIds.push(_id);
      return articulos[0].porcentaje;
    });

  accDiscounts.push(discountsByProduct);

  let discountsByLinesIds = [];
  let discountsByLines = discounts
    .filter(
      ({ lineaArticulos = [], _id }) =>
        !discountsByProductIds.includes(_id) &&
        lineaArticulos.some(({ linea }) => linea === product.linea_articulo)
    )
    .map(({ lineaArticulos = [], _id }) => ({
      _id,
      lineaArticulos: lineaArticulos.filter(
        ({ linea }) => linea === product.linea_articulo
      ),
    }))
    .map(({ lineaArticulos, _id }) => {
      discountsByLinesIds.push(_id);
      return lineaArticulos[0].porcentaje;
    })
    .filter((i) => i);
  accDiscounts.push(discountsByLines);

  let discountProductsLines = discountsByProductIds.concat(discountsByLinesIds);

  let general = discounts
    .filter(({ _id }) => !discountProductsLines.includes(_id))
    .map(({ porcentaje }) => porcentaje);
  accDiscounts.push(general);

  accDiscounts = accDiscounts.flat();

  return accDiscounts;
};

const validatePercentVolumenPromo = (
  discounts,
  product,
  quantity,
  price,
  type
) => {
  let accDiscounts = [];
  let discountsByProductIds = [];
  let discountsByProduct = discounts
    .filter(({ articulos = [] }) =>
      articulos.some(
        ({ articulo, cantidadMinima, precioListaId }) =>
          !_.isEmpty(precioListaId) &&
          articulo === product._id &&
          (type === "volume" ? quantity >= cantidadMinima : true) &&
          (precioListaId.id === price.precio_empresa_id ||
            precioListaId.id === 0)
      )
    )
    .map(({ articulos = [], _id }) => ({
      _id,
      articulos: articulos.filter(({ articulo, cantidadMinima }) => {
        return (
          (type === "volume" ? quantity >= cantidadMinima : true) &&
          articulo === product._id
        );
      }),
    }))
    .map(({ articulos, _id }) => {
      discountsByProductIds.push(_id);
      const max = Math.max(...articulos.map(({ porcentaje }) => porcentaje));
      return max;
    });

  accDiscounts.push(discountsByProduct);

  let discountsByLinesIds = [];
  let discountsByLines = discounts
    .filter(
      ({ lineaArticulos = [], _id }) =>
        !discountsByProductIds.includes(_id) &&
        lineaArticulos.some(
          ({ linea, cantidadMinima, precioListaId }) =>
            !_.isEmpty(precioListaId) &&
            linea === product.linea_articulo &&
            (type === "volume" ? quantity >= cantidadMinima : true) &&
            (precioListaId.id === price.precio_empresa_id ||
              precioListaId.id === 0)
        )
    )
    .map(({ lineaArticulos = [], _id }) => ({
      _id,
      lineaArticulos: lineaArticulos.filter(
        ({ linea, cantidadMinima }) =>
          (type === "volume" ? quantity >= cantidadMinima : true) &&
          linea === product.linea_articulo
      ),
    }))
    .map(({ lineaArticulos, _id }) => {
      discountsByLinesIds.push(_id);

      const max = Math.max(
        ...lineaArticulos.map(({ porcentaje }) => porcentaje)
      );
      return max;
    });
  accDiscounts.push(discountsByLines);

  let discountProductsLines = discountsByProductIds.concat(discountsByLinesIds);
  let general = discounts
    .filter(({ _id }) => !discountProductsLines.includes(_id))
    .map(({ volumenes = [] }) =>
      volumenes
        .filter(
          ({ precioListaId, cantidadMinima }) =>
            !_.isEmpty(precioListaId) &&
            (type === "volume" ? quantity >= cantidadMinima : true) &&
            (precioListaId.id === price?.precio_empresa_id ||
              precioListaId.id === 0)
        )
        .map(({ porcentaje }) => porcentaje)
    )
    .map((porcentajes) => {
      const max = Math.max(porcentajes);
      return max;
    })
    .flat();
  accDiscounts.push(general);

  accDiscounts = accDiscounts.flat();
  return accDiscounts;
};

const calculateMaxDiscount = (descMaximos = [], product) => {
  const { linea_articulo, _id } = product;

  const descGenMaximo = descMaximos.find((i) => i.descuentoMaximo > 0) || null;

  const descGenMaximoArt =
    descMaximos
      .flatMap((i) => i.articulos.find((j) => j.articulo === _id))
      .find((descArt) => descArt !== undefined) || null;

  const descGenMaximoLinea =
    descMaximos
      .flatMap((i) => i.lineaArticulos.find((j) => j.linea === linea_articulo))
      .find((descLinea) => descLinea !== undefined) || null;

  // Valida cada uno de los descuentos por volumen, si no existe lo iguala a 0
  const porcentajeMaximo =
    descGenMaximoArt?.descuentoMaximo ||
    descGenMaximoLinea?.descuentoMaximo ||
    descGenMaximo?.descuentoMaximo ||
    0;

  return porcentajeMaximo;
};

export const getAppliedDiscountProducts = (
  discounts = [],
  product,
  type,
  quantity,
  price
) => {
  if (discounts.length === 0) {
    return {
      percentages: [],
      isExclusive: false,
    };
  }
  const exclusive = discounts.filter(({ exclusive }) => exclusive);
  const isExclusive = exclusive.length > 0;
  let newDiscounts = isExclusive ? exclusive : discounts;

  if (type === "product") {
    return {
      percentages: validatePercentProduct(newDiscounts, product),
      isExclusive,
    };
  }
  if (["promo", "volume"].includes(type)) {
    return {
      percentages: validatePercentVolumenPromo(
        newDiscounts,
        product,
        quantity,
        price,
        type
      ),
      isExclusive,
    };
  }
  return {
    percentages: [],
    isExclusive: false,
  };
};

export const getProductValidations = ({
  product,
  priceToUse = null,
  discounts = [],
  descPromoAplicada = [],
  descVolumenAplicada = [],
  descMaximos = [],
  quantity = 1,
}) => {
  let { precios = [] } = product;
  // Obtiene el precio de lista del artículo
  let defaultPriceList = precios?.find((i) => i.precio_empresa_id === 42);

  let selectedPrice = precios?.find((i) => i.precio_empresa_id === priceToUse);

  const finalPrice =
    priceToUse && selectedPrice ? selectedPrice : defaultPriceList;

  const { percentages: percentageProducts, isExclusive: isExclusiveProducts } =
    getAppliedDiscountProducts(discounts, product, "product");

  const { percentages: percentageVolume, isExclusive: isExclusiveVolume } =
    getAppliedDiscountProducts(
      descVolumenAplicada,
      product,
      "volume",
      quantity,
      finalPrice
    );

  const { percentages: percentagePromo, isExclusive: isExclusivePromo } =
    getAppliedDiscountProducts(
      descPromoAplicada,
      product,
      "promo",
      quantity,
      finalPrice
    );

  let exclusives = [];
  let allDiscounts = [
    ...percentageProducts,
    ...percentageVolume,
    ...percentagePromo,
  ];

  if (isExclusiveProducts) {
    exclusives.push(percentageProducts);
  }
  if (isExclusiveVolume) {
    exclusives.push(percentageVolume);
  }
  if (isExclusivePromo) {
    exclusives.push(percentagePromo);
  }
  exclusives = exclusives.flat();

  const porcentajeMaximo = calculateMaxDiscount(descMaximos, product);

  if (exclusives.length > 0) {
    const percentDefault = Math.max(...exclusives);
    return {
      price: finalPrice?.precio,
      percentDefault:
        porcentajeMaximo > 0 && percentDefault > porcentajeMaximo
          ? porcentajeMaximo
          : percentDefault,
    };
  }
  if (allDiscounts.length > 0) {
    const percentDefault = calculateCumulativeDiscount(allDiscounts);
    return {
      price: finalPrice?.precio,
      percentDefault:
        porcentajeMaximo > 0 && percentDefault > porcentajeMaximo
          ? porcentajeMaximo
          : percentDefault,
    };
  }

  return {
    price: finalPrice?.precio,
    percentDefault: 0,
  };
};
