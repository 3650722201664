import { Card, EmptyState } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ItemDetailStyled } from ".";
import { addClientInfo } from "../../actions/ShoppingCartActions";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";
import ComercioDetalleSkeleton from "../ComercioDetalleSkeleton";
import CustomCarousel from "../CustomCarousel";
import Layer from "../Layout/Layout";
import "./SectionsStyled.css";

export default function ProductInformationStyled({ section, mode = "view" }) {
  const { handle } = useParams();
  const { properties } = section;

  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [articuloDB, setArticuloDB] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quantityError, setQuantityError] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [canShowStock, setCanShowStock] = useState(false);
  const [impuestoDB, setImpuestoDB] = useState(null);
  const [descuentoVolumen, setPorVolumen] = useState();
  const [descuentoMaximo, setPorMaximo] = useState();
  const [descuentoPromocion, setPorPromosGen] = useState();
  const [isValidatingData, setIsValidatingData] = useState(true);

  // const formartVideos = ["mp4", "MP4", "mov", "MOV", "youtube", "vimeo"];
  // const formartImages = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"];

  useEffect(() => {
    const fetchArticle = () => {
      if (mode === "edit") {
        ApiServiceComercios.obtenerArticulos({ query: "", limit: 1 })
          .then(({ articulos }) => {
            if (articulos.length > 0) {
              const obj = { ...articulos[0] };
              setArticuloDB(obj);
              createImagesGroup(obj?.imagen || []);
            }
          })
          .finally(() => setIsLoading(false));
      } else {
        ApiServiceComercios.obtenerArticulo(null, handle)
          .then(({ articulo }) => {
            const obj = { ...articulo };
            if (obj.isParent) {
              obj["variants"] = obj.variants.filter((j) => {
                if (j) {
                  const { variantId = {} } = j;
                  let {
                    existencia: existenciaVar = [],
                    continuar_vendiendo = false,
                    sales_channels = {},
                  } = variantId || {};
                  const { online_store = false } = sales_channels;

                  existenciaVar = existenciaVar.filter(
                    (i) => i.almacen_id === Number(cliente?.almacen?.id)
                  );

                  let ex = existenciaVar.reduce(
                    (prev, current) => prev + current.existencia,
                    0
                  );

                  ex = ex > 0;
                  return online_store && (ex || continuar_vendiendo);
                } else {
                  return false;
                }
              });
              if (obj["variants"].length === 0) return null;
              const optionValues = [];

              obj.variants.forEach((variant) => {
                Array.from({ length: 10 }, (_, index) => index + 1).forEach(
                  (value) => {
                    if (variant.hasOwnProperty(`option${value}`)) {
                      optionValues.push(variant[`option${value}`]);
                    }
                  }
                );
              });

              const newOptions = obj.options.map((opt) => ({
                ...opt,
                values: opt.values.filter((i) => optionValues.includes(i)),
              }));
              obj["options"] = newOptions;
            }
            setArticuloDB(obj);
            createImagesGroup(articulo?.imagen || []);
          })
          .finally(() => setIsLoading(false));
      }
      setIsValidatingData(true);
      ApiServiceComercios.obtenerPedidoDatos()
        .then(
          ({
            ok,
            cliente,
            descuentos,
            impuesto,
            isActivePayment,
            usePaymentsInOrders,
          }) => {
            if (ok) {
              if (cliente) {
                let currency = cliente.moneda;

                let shippingFees = getShippingFees(
                  cliente?.envio?.shippingFees,
                  currency
                );
                // Valores por default
                setCliente(cliente);
                setImpuestoDB(impuesto);

                const {
                  promos,
                  maximos,
                  volumenes,
                  productsDiscount,
                  priceToUse,
                } = getDiscounts(descuentos, cliente);
                setPorPromosGen(promos);
                setPorVolumen(volumenes);
                setPorMaximo(maximos);

                dispatch(
                  addClientInfo({
                    limiteCredito: cliente.limite_credito,
                    clienteEnvio: cliente.envio,
                    impuesto,
                    moneda: currency,
                    customerData: cliente,
                    shippingFees,
                    discounts: productsDiscount,
                    priceToUse,
                    isActivePayment,
                    vendedor: cliente?.vendedor,
                    status_microsip: cliente?.status_microsip,
                    usePaymentsInOrders,
                  })
                );
              }
            }
          }
        )
        .finally(() => setIsValidatingData(false));
    };

    function createImagesGroup(img = []) {
      setImages(img);
    }
    fetchArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handle, mode, cliente?.almacen?.id]);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        setCanShowStock(preferencia?.mostrarExistenciaProducto);
      }
    );
  }, []);

  function onChangeVariant(imgs = []) {
    setImages(imgs);
  }

  if (isLoading || isValidatingData) {
    return (
      <div>
        <ComercioDetalleSkeleton />;
      </div>
    );
  }

  if (articuloDB === null) {
    return (
      <Layer title={`Artículo`} forbidden={false}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este artículo"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a artículos",
            url: "/admin/articulos",
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  return (
    <div className="flex flex-row p-8" style={{ backgroundColor: "#F4F6F8" }}>
      <div className="flex-1">
        <Card sectioned>
          <CustomCarousel img={images} />
        </Card>
      </div>
      <ItemDetailStyled
        itemData={articuloDB}
        almacenId={cliente?.almacen?.id}
        discounts={{
          descuentoPromocion,
          descuentoVolumen,
          descuentoMaximo,
        }}
        showTax={impuestoDB?.mostrarPrecioConImpuesto}
        onChangeVariant={onChangeVariant}
        quantityError={quantityError.length}
        setQuantityError={(value, id) =>
          setQuantityError((prevState) =>
            value
              ? prevState.some((errorId) => errorId === id)
                ? prevState
                : [...prevState, id]
              : prevState.filter((errorId) => errorId !== id)
          )
        }
        canShowStock={canShowStock}
        properties={properties}
      />
    </div>
  );
}
