import { Button, Icon } from "@shopify/polaris";
import {
  ChevronLeftMinor,
  ChevronRightMinor,
  PlayCircleMajor,
} from "@shopify/polaris-icons";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

export const CustomCarousel = ({ img = [] }) => {
  const player = useRef(null);
  const [multimedia, setMultimedia] = useState([]);
  const [currentMedia, setCurrentMedia] = useState(0);
  const formartVideos = ["mp4", "MP4", "mov", "MOV", "youtube", "vimeo"];
  const formartImages = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"];

  useEffect(() => {
    if (img.length > 0) {
      img.sort();
      const imgs = [];
      const vids = [];
      if (img.length > 0) {
        for (let i = 0; i < formartImages.length; i++) {
          img.forEach((image) => {
            if (image.includes(formartImages[i])) {
              imgs.push({
                src: image,
                isImage: true,
              });
            }
          });
        }

        for (let i = 0; i < formartVideos.length; i++) {
          img.forEach((video) => {
            if (video.includes(formartVideos[i])) {
              vids.push({
                src: video,
                isImage: false,
              });
            }
          });
        }
      }
      setMultimedia([...imgs, ...vids]);
    }
    // eslint-disable-next-line
  }, []);

  const prev = () => {
    setCurrentMedia((curr) => (curr === 0 ? multimedia?.length - 1 : curr - 1));
  };

  const next = () => {
    setCurrentMedia((curr) => (curr === multimedia?.length - 1 ? 0 : curr + 1));
  };

  const onChangeMedia = (url) => {
    let index = multimedia.findIndex((i) => i?.src === url);
    setCurrentMedia(index);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-2">
        <div className="flex justify-center items-center w-full h-full relative">
          {multimedia[currentMedia]?.isImage ? (
            <img
              width={"100%"}
              src={multimedia[currentMedia]?.src || "/Default Photo.png"}
              alt="imagen"
            />
          ) : (
            <ReactPlayer
              ref={player}
              url={multimedia[currentMedia]?.src}
              controls={true}
              autoPlay={true}
              playing={true}
              width={"100%"}
            />
          )}
          <div className="absolute inset-0 flex justify-between items-end h-1/2 mx-4 ">
            <button
              onClick={prev}
              className="p-2 rounded-full shadow bg-white hover:bg-gray-200 border-1 border-gray-300"
            >
              <Icon source={ChevronLeftMinor} />
            </button>
            <button
              onClick={next}
              className="p-2 rounded-full shadow bg-white hover:bg-gray-200 border-1 border-gray-300"
            >
              <Icon source={ChevronRightMinor} />
            </button>
          </div>
        </div>
      </div>

      <div className="flex object-cover items-center justify-centers w-full h-full gap-2 relative mt-2">
        {multimedia.map((media, index) => (
          <div key={index} onClick={() => onChangeMedia(media.src)}>
            {media.isImage ? (
              <div
                key={index}
                className={
                  multimedia[currentMedia]?.src === media.src
                    ? "border-2 border-gray-300 rounded-lg p-2 border-blue-500"
                    : "border-2 border-gray-300 rounded-lg p-2"
                }
              >
                <img
                  style={{ width: 70, height: 70, objectFit: "cover" }}
                  src={media.src || "/Default Photo.png"}
                  alt="imagen"
                />
              </div>
            ) : (
              <div
                key={index}
                style={{
                  backgroundColor: "black",
                  width: 86,
                  height: 86,
                }}
                className={
                  multimedia[currentMedia]?.src === media.src
                    ? "border-2 rounded-lg p-2 border-blue-500 flex justify-center items-center"
                    : "border-2 border-gray-300 rounded-lg p-2 flex justify-center items-center"
                }
              >
                <div className="absolute z-10">
                  <Button size="large" icon={PlayCircleMajor} plain />
                </div>
                <video>
                  <source src={`${media.src}#t=3`} type="video/mp4" />
                </video>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
