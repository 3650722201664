import {
  ActionList,
  Autocomplete,
  Badge,
  Banner,
  Button,
  Card,
  Checkbox,
  ContextualSaveBar,
  DataTable,
  DropZone,
  EmptyState,
  FormLayout,
  Heading,
  Icon,
  Layout,
  Link,
  List,
  Page,
  PageActions,
  Popover,
  Select,
  Spinner,
  Stack,
  TextField,
  TextStyle,
} from "@shopify/polaris";
import { CirclePlusMajor, LocationsMinor } from "@shopify/polaris-icons";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import SunEditor, { buttonList } from "suneditor-react";
import { toggleToast } from "../../actions/InteractiveActions";
import {
  AddUrlImageModal,
  ComercioDetalleSkeleton,
  DeleteModal,
  HelpFooter,
  Layer,
  TagsAutocomplete,
  VariantList,
} from "../../components";
import ZoomImage from "../../components/ZoomImage/ZoomImage";
import useToggle from "../../hooks/useToggle";
import { getPaginationArticulos } from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { Colors } from "../../styles";
import { NormalizeName } from "../../utils/Formats";
import { CollectionsAutocomplete } from "./components/CollectionsAutocomplete";
import PackagingKeys from "./components/PackagingKeys";
import { validImageTypes } from "../../constants/dataFormat";
import { getImageVideoUrl } from "../../utils/productValidators";
const maxSize = 262144001; //250 MB

export default function ArticuloDetalleScreen({ forbidden }) {
  const [isActiveSucursal, toggleSucursal] = useToggle();

  const location = useLocation().state;
  const { id } = useParams();
  const dispatch = useDispatch();
  const router = useHistory();
  const defaultArticulo = useRef({});
  const [articuloDB, setArticuloDB] = useState(null);
  const [packingKeys, setPackingKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const nombre = useSelector((state) => state.Auth.nombre_comercio);
  const [imagesFromUrl, setImagesFromUrl] = useState([]);
  const [imagesFilesFromUrl, setImagesFilesFromUrl] = useState([]);
  const [imageFieldUrl, setImageFieldUrl] = useState("");
  const [pageState, setPageState] = useState([]);
  const pagination = useSelector(getPaginationArticulos);
  const [lastSync, setLastSync] = useState(null);
  const [showAllStock, setShowAllStock] = useState(false);
  const [stockItems, setStockItems] = useState([]);
  const [stockItemsFiltered, setStockItemsFiltered] = useState([]);
  const [errorArticulos, setErrorArticulos] = useState({
    message: "",
    articles: [],
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [deselectedOptionsBrands, setDeselectedOptionsBrands] = useState([]);
  const [optionsBrand, setOptionsBrand] = useState(deselectedOptionsBrands);
  const [brandsSelected, setSelectedOptionsBrand] = useState([]);
  const [isAddingBrand, setIsAddingBrand] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [sucursales, setSucursales] = useState([
    {
      content: "Todas las sucursales",
      id: 0,
      onAction: () => onChangeSucursal(0),
    },
  ]);
  const [sucursalSelected, setSucursalSelected] = useState(0);
  const [minimum, setMinimum] = useState(1);
  const [maximum, setMaximum] = useState(0);
  const [factorVenta, setFactorVenta] = useState(1);
  const [fieldsError, setFieldsError] = useState({
    minimum: null,
    maximum: null,
    factor_venta: null,
  });
  const [isAdding, setIsAdding] = useState(false);
  const [onlyImages, setOnlyImages] = useState([]);
  const [onlyVideos, setOnlyVideos] = useState([]);
  const [videosFromUrl, setVideosFromUrl] = useState([]);

  useEffect(() => {
    if (location && location.hasOwnProperty("allIds")) {
      setPageState(location.allIds);
    }
  }, [location]);

  useEffect(() => {
    const coincidence = getImageVideoUrl(articuloDB?.imagen, "image");
    const coincidenceVideos = getImageVideoUrl(articuloDB?.imagen, "video");

    setOnlyImages(coincidence);
    setOnlyVideos(coincidenceVideos);
  }, [articuloDB]);

  const onChangeSucursal = useCallback(
    (value) => {
      setSucursalSelected(value);
      toggleSucursal();
    },
    [toggleSucursal]
  );

  useEffect(() => {
    ApiServiceComercios.obtenerSucursales().then(({ ok, sucursales }) => {
      if (ok) {
        setSucursales((state) => [
          ...state,
          ...sucursales.map((i) => ({
            content: i.nombre,
            id: i.id,
            onAction: () => onChangeSucursal(i.id),
          })),
        ]);
      }
    });

    ApiServiceComercios.obtenerArticulo(id)
      .then(({ articulo, ok, sincronizacion }) => {
        if (ok) {
          const articleTags = articulo.etiquetas?.filter((i) => i) || [];

          defaultArticulo.current = { ...articulo };

          setArticuloDB({
            ...articulo,
            etiquetas: articleTags,
          });
          setStockItems(articulo?.existencia);
          setPackingKeys(articulo.packingKeys);
          setSelectedOptionsBrand([articulo.marca]);
          setMinimum(articulo.minimum ? Number(articulo.minimum) : Number(1));
          setMaximum(articulo.maximum ? Number(articulo.maximum) : Number(0));
          setFactorVenta(
            articulo.factor_venta ? Number(articulo.factor_venta) : Number(1)
          );
          setLastSync(sincronizacion);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });

    ApiServiceComercios.getBrands().then(({ brands }) => {
      brands = brands.map((item) => ({
        value: item.name,
        label: item.name,
      }));

      setDeselectedOptionsBrands(brands);
      setOptionsBrand(brands);
    });
  }, [id, onChangeSucursal, selectedImages]);

  useEffect(() => {
    if (showAllStock) {
      setStockItemsFiltered(stockItems);
    } else {
      setStockItemsFiltered(
        stockItems?.filter((item) => item?.existencia !== 0)
      );
    }
  }, [showAllStock, stockItems]);

  useEffect(() => {
    if (maximum < minimum) {
      setFieldsError((state) => ({
        ...state,
        maximum: "Debe ser mayor al mínimo",
      }));
    } else if (maximum % factorVenta !== 0) {
      setFieldsError((state) => ({
        ...state,
        maximum: "Debe ser múltiplo del factor de venta",
      }));
    } else {
      setFieldsError((state) => ({
        ...state,
        maximum: null,
      }));
    }
    if (minimum < factorVenta) {
      setFieldsError((state) => ({
        ...state,
        minimum: "No debe ser menor al factor de venta",
      }));
    } else {
      setFieldsError((state) => ({
        ...state,
        minimum: null,
      }));
    }
    if (maximum === 0) {
      setFieldsError((state) => ({
        ...state,
        maximum: "",
      }));
    }
    if (maximum < factorVenta && maximum > 0) {
      setFieldsError((state) => ({
        ...state,
        factor_venta: "Debe ser menor al máximo",
      }));
    } else {
      setFieldsError((state) => ({
        ...state,
        factor_venta: null,
      }));
    }
  }, [setMinimum, setMaximum, setFactorVenta, minimum, maximum, factorVenta]);

  const handleFieldChange = useCallback((value, key) => {
    setArticuloDB((state) => ({ ...state, [key]: value }));
    setIsDirty(true);
  }, []);

  const addNewBrand = () => {
    setIsAddingBrand(true);
    let brand = articuloDB.marca.trim();
    ApiServiceComercios.createBrand({
      name: brand,
    })
      .then(() => {
        const item = { value: brand, label: brand };
        setOptionsBrand((prev) => [...prev, item]);
        setDeselectedOptionsBrands((prev) => [...prev, item]);
        setSelectedOptionsBrand([brand]);
      })
      .finally(() => setIsAddingBrand(false));
  };

  function seeHistoric() {
    router.push(`/admin/historial`, { comercio: { nombre } });
  }
  function handleDropZoneDrop(_dropFiles, acceptedFiles = [], _rejectedFiles) {
    // setIsDirty(true);
    // setIsAdding(true);
    const formData = new FormData();
    let newAcceptedFiles = acceptedFiles.filter((file) => {
      return file.size < maxSize;
    });
    if (newAcceptedFiles.length > 0) {
      newAcceptedFiles.forEach((file) => {
        formData.append("files", file, NormalizeName(file.name));
      });

      ApiServiceComercios.postFiles(formData)
        .then(({ files = [] }) => {
          const urls = files.map(({ url }) => url);
          setArticuloDB((state) => ({
            ...state,
            imagen: _.isArray(state.imagen) ? state.imagen.concat(urls) : urls,
          }));
          handleSave(urls);
        })
        .finally(() => {
          setIsAdding(false);
        });
    }
  }

  function handleSave(imgFiles = [], deleteImages = false) {
    setIsEditing(true);
    setErrorArticulos({ message: "", articles: [] });

    const productFiles =
      _.isArray(articuloDB.imagen) && articuloDB.imagen.length > 0
        ? articuloDB.imagen
        : [];

    let articuloData = {
      nombre: articuloDB.nombre,
      descripcion: articuloDB.descripcion,
      peso: parseFloat(articuloDB.peso),
      factor_venta: articuloDB.factor_venta,
      continuar_vendiendo: articuloDB.continuar_vendiendo,
      minimum: Number(minimum),
      maximum: Number(maximum),
      packingKeys: packingKeys,
      marca: articuloDB.marca,
      etiquetas: articuloDB.etiquetas,
      imagen: deleteImages
        ? productFiles.filter((i) => !selectedImages.includes(i))
        : productFiles.concat(imgFiles),
    };

    ApiServiceComercios.actualizarArticulo(articuloData, id)
      .then(({ message }) => {
        setArticuloDB((state) => ({ ...state }));
        dispatch(toggleToast({ message }));
        setIsDirty(false);
        setSelectedImages([]);
        setImagesFromUrl([]);
        setVideosFromUrl([]);
      })
      .catch((error) => {
        const { response } = error;
        if (response?.data) {
          const { data } = response;
          const dataForError = {
            message: "",
            articulos: [],
          };
          if (data && data.message) {
            dataForError["message"] = data.message;
          }
          if (data && data.articles) {
            dataForError["articles"] = data.articles;
          }
          setErrorArticulos(dataForError);
        }
      })
      .finally(() => setIsEditing(false));
  }

  function handleDiscard() {
    router.goBack();
  }

  function editDescription(value, key) {
    const a = defaultArticulo.current.descripcion
      .replace("<p>", "")
      .replace("</p>", "")
      .trim();
    const b = value.replace("<p>", "").replace("</p>", "").trim();
    if (a !== b) {
      setIsDirty(true);
    }
    setArticuloDB((state) => ({ ...state, [key]: value }));
  }

  function addImagesFromUrl() {
    if (imagesFilesFromUrl.length > 0) {
      setIsAdding(true);

      const formData = new FormData();
      if (imagesFilesFromUrl.length > 0) {
        imagesFilesFromUrl.forEach((file) => {
          formData.append("files", file);
        });

        ApiServiceComercios.postFiles(formData)
          .then(({ files = [] }) => {
            const urls = files.map(({ url }) => url);
            setArticuloDB((state) => ({
              ...state,
              imagen: _.isArray(state.imagen)
                ? state.imagen.concat(urls)
                : urls,
            }));
            setImagesFilesFromUrl([]);
            setIsActiveModal(false);
            setVideosFromUrl([]);
            setIsDirty(true);
            setTimeout(() => {
              handleSave();
            }, 200);
          })
          .finally(() => {
            setIsAdding(false);
          });
      }
    } else {
      if (videosFromUrl.length > 0) {
        setArticuloDB((state) => ({
          ...state,
          imagen: _.isArray(state.imagen)
            ? state.imagen.concat(videosFromUrl)
            : videosFromUrl,
        }));
        setVideosFromUrl([]);
        setIsActiveModal(false);
        setIsDirty(true);
        handleSave(videosFromUrl);
      }
    }
  }

  function addImageItem() {
    let extension = imageFieldUrl.split(".");
    extension = extension[extension.length - 1];
    if (
      imagesFromUrl.indexOf(imageFieldUrl) < 0 &&
      validImageTypes.indexOf(extension)
    ) {
      setImagesFromUrl((state) => [...state, imageFieldUrl]);
      setImageFieldUrl("");
    }
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/admin/articulos/${prevId}`, { allIds: pageState });
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/admin/articulos/${nextId}`, { allIds: pageState });
  }

  function synchronize() {
    ApiServiceComercios.synchronizeArticle(articuloDB.id).finally(() => {
      dispatch(toggleToast({ message: "En breve se actualizarán los datos" }));
    });
  }

  function handleSelectImage(value, image) {
    if (value) {
      if (!selectedImages.some((i) => i === image)) {
        setSelectedImages([...new Set(selectedImages.concat(image))]);
      }
    } else {
      setSelectedImages(selectedImages.filter((i) => i !== image));
    }
  }

  function navigate(_id) {
    router.push(`/admin/articulos/${id}/variant/${_id}`);
  }

  const updateTextBrand = (txt) => {
    handleFieldChange(txt, "marca");

    if (!txt) {
      setOptionsBrand(deselectedOptionsBrands);
      return;
    }

    const filterRegex = new RegExp(txt, "i");
    const resultOptions = deselectedOptionsBrands.filter((option) =>
      option.label.match(filterRegex)
    );
    setOptionsBrand(resultOptions);
  };

  const onSelectBrand = (selected) => {
    const selectedValue = selected.map((item) => {
      const matchedOption = optionsBrand.find((option) => {
        return option.value.match(item);
      });
      return matchedOption && matchedOption.label;
    });

    setSelectedOptionsBrand(selected);
    handleFieldChange(selectedValue[0], "marca");
    setIsDirty(true);
  };

  const validateExistence = () => {
    const currentOption = optionsBrand.find(
      (item) => item.label === articuloDB?.marca?.trim()
    );
    return currentOption ? currentOption.label : "";
  };

  const deleteArticle = () => {
    setIsSavingChanges(true);

    ApiServiceComercios.deleteArticle(id).then((res) => {
      handleDiscard();
      dispatch(
        toggleToast({
          message: res.message,
        })
      );
    });
  };

  if (isLoading) {
    return (
      <Layer title={`Artículo`}>
        <ComercioDetalleSkeleton />
      </Layer>
    );
  }

  if (articuloDB === null) {
    return (
      <Layer title={`Artículo`}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este artículo"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a artículos",
            onAction: handleDiscard,
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  const contextualSave = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: () => handleSave(),
        disabled:
          !isDirty ||
          fieldsError.maximum ||
          fieldsError.factor_venta ||
          fieldsError.minimum,
        loading: isEditing,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isEditing,
      }}
    />
  ) : null;

  const textFieldBrand = (
    <Autocomplete.TextField
      label="Marca"
      onChange={updateTextBrand}
      value={articuloDB.marca}
      clearButton
      onClearButtonClick={() => {
        updateTextBrand("");
        setSelectedOptionsBrand([]);
      }}
    />
  );

  const fileUpload = (
    <div
      className={`${
        onlyImages.length > 0 || onlyVideos.length > 0
          ? "dropzone-container"
          : ""
      }`}
    >
      <DropZone.FileUpload actionHint="Acepta imágenes y videos." />
    </div>
  );

  return (
    <Layer title="Artículo detalle" forbidden={forbidden} scrollToTop>
      <Page
        title={articuloDB.nombre}
        breadcrumbs={[{ content: "Artículos", onAction: handleDiscard }]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente artículo",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Artículo anterior",
        }}
      >
        {contextualSave}
        {isActiveModal && (
          <AddUrlImageModal
            addImageItem={addImageItem}
            addImagesFromUrl={addImagesFromUrl}
            imageFieldUrl={imageFieldUrl}
            imagesFromUrl={imagesFromUrl}
            isActiveModal={isActiveModal}
            setImageFieldUrl={setImageFieldUrl}
            setIsActiveModal={setIsActiveModal}
            setImagesFilesFromUrl={setImagesFilesFromUrl}
            videosFromUrl={videosFromUrl}
            setVideosFromUrl={setVideosFromUrl}
          />
        )}
        <Layout>
          {errorArticulos.length > 0 && (
            <div className="py-5">
              <Banner
                status="warning"
                title="El artículo tiene los siguientes errores"
              >
                <List>
                  {errorArticulos.map((item, index) => (
                    <List.Item key={index}>{item?.message}</List.Item>
                  ))}
                </List>
              </Banner>
            </div>
          )}
          <Layout.Section>
            <Card sectioned>
              <FormLayout>
                <TextField
                  label="Nombre"
                  type="text"
                  value={articuloDB.nombre}
                  onChange={(value) => {
                    handleFieldChange(value, "nombre");
                  }}
                />
                <div className="flex flex-col">
                  <TextStyle>Descripción</TextStyle>
                  <SunEditor
                    lang="es"
                    setContents={articuloDB.descripcion}
                    defaultValue={articuloDB.descripcion}
                    placeholder="Escribe una descripción para este artículo"
                    setOptions={{
                      buttonList: buttonList.complex,
                    }}
                    height="300"
                    onBlur={() => null}
                    autoFocus={false}
                    onFocus={() => null}
                    onClick={() => null}
                    onChange={(content) => {
                      editDescription(content, "descripcion");
                    }}
                  />
                </div>
              </FormLayout>
            </Card>
            <Card>
              <Card.Section
                title={
                  <div className="normal-case">
                    <Heading>Multimedia</Heading>
                  </div>
                }
                actions={[
                  {
                    content:
                      selectedImages.length > 0 ? (
                        isEditing ? (
                          <Spinner size="small" />
                        ) : (
                          <TextStyle>Eliminar elementos multimedia</TextStyle>
                        )
                      ) : (
                        "Agrega desde una URL"
                      ),
                    onAction:
                      selectedImages.length > 0
                        ? () => handleSave([], true)
                        : () => {
                            setIsActiveModal(true);
                          },
                    destructive: selectedImages.length > 0,
                  },
                ]}
              >
                <ZoomImage
                  // images={articuloDB.imagen}
                  images={onlyImages}
                  videos={onlyVideos}
                  selectedImages={selectedImages}
                  handleSelectImage={handleSelectImage}
                >
                  <div className="flex flex-row w-full">
                    <DropZone
                      labelHidden
                      outline
                      type="file"
                      accept={validImageTypes.join(",")}
                      onDrop={handleDropZoneDrop}
                      allowMultiple={true}
                      overlay
                      disabled={isAdding}
                    >
                      {fileUpload}
                    </DropZone>
                  </div>
                </ZoomImage>
              </Card.Section>
            </Card>

            {articuloDB.isParent ? (
              <Card>
                <Card.Header title="Variantes">
                  <Button plain onClick={synchronize}>
                    Actualizar
                  </Button>
                </Card.Header>
                <Card.Section>
                  <Stack>
                    <TextStyle variation="subdued">
                      Inventario disponible
                    </TextStyle>
                    <Popover
                      active={isActiveSucursal}
                      activator={
                        <Button
                          disclosure
                          onClick={toggleSucursal}
                          plain
                          icon={LocationsMinor}
                        >
                          {
                            sucursales.find((i) => i.id === sucursalSelected)[
                              "content"
                            ]
                          }
                        </Button>
                      }
                      onClose={toggleSucursal}
                    >
                      <ActionList items={sucursales} />
                    </Popover>
                  </Stack>
                </Card.Section>
                <VariantList
                  onNavigate={navigate}
                  sucursalSelectedName={
                    sucursales.find((i) => i.id === sucursalSelected)["content"]
                  }
                  sucursalSelected={sucursalSelected}
                  items={articuloDB.variants}
                />
              </Card>
            ) : (
              <>
                <Card
                  sectioned
                  title="Precio"
                  actions={[{ content: "Actualizar", onAction: synchronize }]}
                >
                  <DataTable
                    headings={["Lista precio", "Precio", "Moneda"]}
                    columnContentTypes={["text", "numeric", "numeric"]}
                    rows={articuloDB.precios?.map((item) => {
                      return [
                        item?.nombre_precio || "",
                        "$" +
                          Number(item?.precio || 0).toLocaleString("en", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }),
                        item?.moneda || "",
                      ];
                    })}
                  />
                </Card>
                <Card title="Inventario">
                  <Card.Section>
                    <Stack distribution="fill" wrap spacing="loose">
                      <TextField
                        disabled
                        label="SKU (Clave del producto)"
                        type="text"
                        value={articuloDB.sku}
                        onChange={() => {}}
                      />
                      <TextField
                        disabled
                        label="Código de barras (ISBN, UPC, GTIN, etc.)"
                        type="text"
                        value={articuloDB.codigo_barra}
                        onChange={() => {}}
                      />
                    </Stack>
                    <div className="my-4" />
                    <Checkbox
                      disabled={isEditing}
                      label="Continuar vendiendo cuando se haya agotado"
                      onChange={(value) =>
                        handleFieldChange(value, "continuar_vendiendo")
                      }
                      checked={articuloDB.continuar_vendiendo}
                    />
                  </Card.Section>
                  <Card.Section title="Unidades de venta">
                    <FormLayout>
                      <TextStyle>
                        Se usa para vender los artículos en mínimos, máximos y
                        múltiplos de un factor de venta.
                      </TextStyle>
                      <Stack distribution="fill" wrap={false} spacing="loose">
                        <TextField
                          label="Mínimo"
                          type="number"
                          min={factorVenta}
                          value={String(minimum)}
                          onChange={(value) => {
                            handleFieldChange(value, "minimum");
                            setMinimum(Number(value));
                          }}
                          error={fieldsError.minimum}
                        />
                        <TextField
                          label="Máximo"
                          type="number"
                          min={0}
                          value={String(maximum)}
                          onChange={(value) => {
                            handleFieldChange(value, "maximum");
                            setMaximum(Number(value));
                          }}
                          suffix={maximum === 0 && <Badge>Ilimitado</Badge>}
                          error={fieldsError.maximum}
                        />
                      </Stack>
                      <Stack distribution="fillEvenly" wrap spacing="loose">
                        <TextField
                          label="Factor de venta"
                          type="number"
                          min={1}
                          value={String(factorVenta)}
                          onChange={(value) => {
                            handleFieldChange(value, "factor_venta");
                            setFactorVenta(Number(value));
                          }}
                          error={fieldsError.factor_venta}
                        />
                        <Select
                          disabled
                          label="Unidad de medida"
                          options={[
                            {
                              label: articuloDB.unidadmed,
                              value: articuloDB.unidadmed,
                            },
                          ]}
                          onChange={() => {}}
                          value={articuloDB.unidadmed}
                        />
                      </Stack>
                    </FormLayout>
                  </Card.Section>
                </Card>
                {errorArticulos.articles.length > 0 && (
                  <Card>
                    <Banner title="Claves duplicadas" status="warning">
                      <TextStyle>
                        Los siguientes artículos ya cuentan con algunas de las
                        claves registradas
                      </TextStyle>
                      <List type="bullet">
                        {errorArticulos.articles.map((i) => (
                          <List.Item key={i._id}>
                            <Link url={`/admin/articulos/${i._id}`} external>
                              {i.nombre}
                            </Link>
                          </List.Item>
                        ))}
                      </List>
                    </Banner>
                  </Card>
                )}
                <PackagingKeys
                  packingKeys={packingKeys}
                  setIsDirty={setIsDirty}
                  setPackingKeys={setPackingKeys}
                />
                <Card
                  title="Disponibilidad"
                  actions={[{ content: "Actualizar", onAction: synchronize }]}
                >
                  <div className="mx-8 mb-8">
                    <TextStyle variation="subdued">
                      {stockItemsFiltered?.length} de{" "}
                    </TextStyle>
                    <Button
                      plain
                      onClick={() => setShowAllStock(!showAllStock)}
                    >
                      {stockItems?.length} almacenes
                    </Button>
                  </div>
                  {stockItemsFiltered.length > 0 ? (
                    stockItemsFiltered?.map((ext, id) => {
                      return (
                        <div key={id}>
                          <div className="w-full h-px bg-gray-400" />
                          <Card.Section>
                            <div className="flex justify-between flex-wrap">
                              <TextStyle>{ext.nombre_almacen}</TextStyle>
                              <TextStyle>
                                {Number(ext.existencia).toLocaleString("en", {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                })}
                              </TextStyle>
                            </div>
                          </Card.Section>
                        </div>
                      );
                    })
                  ) : (
                    <div className="flex justify-center items-center py-8">
                      <h1 className="text-4xl">
                        No hay disponibilidad en almacenes
                      </h1>
                    </div>
                  )}
                </Card>
                <Card title="Envíos">
                  <Card.Section>
                    <Checkbox
                      disabled
                      label="Este es un producto físico"
                      checked={articuloDB.es_producto_fisico}
                      onChange={() => {}}
                    />
                  </Card.Section>
                  <Card.Section title="Peso">
                    <TextStyle>
                      Se usa para calcular las tarifas de envío al momento del
                      pago.
                    </TextStyle>
                    <div className="mt-8">
                      <TextField
                        label="Peso (Kg)"
                        type="number"
                        min={0}
                        step={1}
                        value={String(articuloDB.peso)}
                        onChange={(value) => handleFieldChange(value, "peso")}
                      />
                    </div>
                  </Card.Section>
                </Card>
              </>
            )}
          </Layout.Section>
          <Layout.Section secondary>
            {/* 
            <Card title="Estado del producto">
              <Card.Section>
                <Select
                  disabled
                  options={[
                    { label: "Activo", value: "Activo" },
                    { label: "Borrador", value: "Borrador" },
                  ]}
                  onChange={(_) => {}}
                  value={"Activo"}
                />
              </Card.Section>
              </Card>
              */}
            <Card title="Canales de venta">
              <Card.Section>
                <TextStyle variation="subdued">
                  Disponible en{" "}
                  {articuloDB.sales_channels?.online_store ? "1" : "0"} de 1
                  canal de venta{" "}
                </TextStyle>
                <div className="flex justify-between mt-8 pt-8 border-t border-gray-300">
                  <TextStyle>Tienda en línea</TextStyle>
                  <div
                    style={{
                      backgroundColor: articuloDB.sales_channels?.online_store
                        ? Colors.greenActive
                        : Colors.orangeDisabled,
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </Card.Section>
            </Card>
            <Card>
              <Card.Section subdued>
                <FormLayout>
                  <Heading>Organización</Heading>
                  <Autocomplete
                    options={_.sortBy(optionsBrand, "label")}
                    selected={brandsSelected}
                    onSelect={onSelectBrand}
                    textField={textFieldBrand}
                    loading={isAddingBrand}
                    willLoadMoreResults
                    listTitle="Marcas disponibles"
                    actionBefore={
                      !validateExistence() && articuloDB?.marca?.trim()
                        ? {
                            content: (
                              <>
                                <div className="flex flex-row items-center cursor-pointer text-link">
                                  <div className="mr-2">
                                    <Icon
                                      source={CirclePlusMajor}
                                      color="interactive"
                                    />
                                  </div>
                                  <TextStyle>
                                    Agregar {articuloDB?.marca}
                                  </TextStyle>
                                </div>
                              </>
                            ),
                            onAction: addNewBrand,
                          }
                        : null
                    }
                  />
                  <Select
                    disabled
                    label="Tipo de producto"
                    options={[
                      {
                        label: articuloDB.linea_articulo,
                        value: articuloDB.linea_articulo,
                      },
                    ]}
                    onChange={(_) => {}}
                    value={articuloDB.linea_articulo}
                  />
                  <CollectionsAutocomplete idProduct={id} />
                </FormLayout>
              </Card.Section>
            </Card>
            <TagsAutocomplete
              title="Etiquetas"
              subdued
              selected={articuloDB.etiquetas || []}
              onSelect={(opts) => handleFieldChange(opts, "etiquetas")}
              outLoading={isEditing}
              module="Artículos"
            />
            {!articuloDB.isParent && (
              <Card title="Impuestos" subdued sectioned>
                <List>
                  {articuloDB.impuestos?.map(
                    ({ nombre, conjunto = "" }, index) => (
                      <List.Item key={index}>
                        {nombre} en {conjunto}
                      </List.Item>
                    )
                  )}
                </List>
              </Card>
            )}
            <Card subdued>
              <Card.Section subdued>
                <div className="flex flex-col">
                  <TextStyle>Microsip</TextStyle>
                  {!lastSync ? (
                    <div className="flex flex-row items-center mt-6">
                      <div className="w-6 h-6 bg-orange-500 rounded-full mr-8 " />
                      <div className="flex flex-col items-start">
                        <TextStyle variation="subdued">
                          Este comercio no tiene sincronizaciones.{" "}
                        </TextStyle>
                        <Button plain onClick={seeHistoric}>
                          Ver historial
                        </Button>
                      </div>
                    </div>
                  ) : lastSync?.estatus_code !== 200 ? (
                    <div className="flex flex-row items-center mt-6">
                      <div className="w-6 h-6 bg-yellow-500 rounded-full mr-8 " />
                      <div className="flex flex-col items-start">
                        <TextStyle variation="subdued">
                          El servidor Microsip no está disponible o está
                          apagado.
                        </TextStyle>
                        <Button plain onClick={seeHistoric}>
                          Ver historial
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-row items-center mt-6">
                      <div className="w-6 h-6 bg-green-500 rounded-full mr-8 " />
                      <div className="flex flex-col items-start">
                        <TextStyle variation="subdued">
                          Este comercio está sincronizado.
                        </TextStyle>
                        <Button plain onClick={seeHistoric}>
                          Ver historial
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Card.Section>
            </Card>
          </Layout.Section>
        </Layout>
        <div className="mt-10">
          <PageActions
            primaryAction={{
              content: "Guardar",
              onClick: () => handleSave(),
              disabled:
                !isDirty ||
                fieldsError.maximum ||
                fieldsError.factor_venta ||
                fieldsError.minimum,
              loading: isEditing,
            }}
            secondaryActions={[
              {
                content: "Eliminar",
                destructive: true,
                outline: true,
                onAction: () => setShowDeleteModal(true),
              },
            ]}
          />

          {
            <DeleteModal
              active={showDeleteModal}
              title="Eliminar artículo"
              body={"Esta acción no puede deshacerse."}
              handleChange={() => setShowDeleteModal(false)}
              handleDelete={deleteArticle}
              isLoading={isSavingChanges}
            />
          }
        </div>

        <HelpFooter title="artículos" url="https://help.b2bgo.mx/articulos" />
      </Page>
    </Layer>
  );
}
