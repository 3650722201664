import {
  Badge,
  Card,
  DisplayText,
  EmptyState,
  Layout,
  Page,
} from "@shopify/polaris";
import { Col, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  ComercioDetalleSkeleton,
  CustomCarousel,
  Layer,
  ShoppingCardButton,
} from "../../components";
import ItemDetail from "../../components/common/ItemDetail";
import ShoppingCart from "../../components/common/ShoppingCart";
import useToggle from "../../hooks/useToggle";

import { addClientInfo } from "../../actions/ShoppingCartActions";
import useGetCurrencies from "../../hooks/useGetCurrencies";
import {
  getPaginationArticulos,
  getTotalArticlesShoppingCart,
} from "../../reducers";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import { getDiscounts, getShippingFees } from "../../utils/orderValidations";
import "./ClienteArticuloDetalle.css";

export default function ClienteArticuloDetalleScreen(props) {
  const { id } = useParams();
  const router = useHistory();
  const dispatch = useDispatch();
  const { state: { almacenId, discounts = {}, showTax = false } = {} } =
    useLocation();
  const [cartActive, toggleCartActive] = useToggle();

  const allArticles = useSelector(getTotalArticlesShoppingCart);
  const pageState = useSelector((state) => state.Articulos.allIdsDB);
  const pagination = useSelector(getPaginationArticulos);

  const [images, setImages] = useState([]);
  const [articuloDB, setArticuloDB] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [quantityError, setQuantityError] = useState([]);
  const [cliente, setCliente] = useState(null);
  const { currencies } = useGetCurrencies();
  const [canShowStock, setCanShowStock] = useState(false);
  const [existencias, setExistencias] = useState([]);

  useEffect(() => {
    function fetchArticle() {
      ApiServiceComercios.obtenerArticulo(id)
        .then(({ articulo }) => {
          const obj = { ...articulo };
          if (obj.isParent) {
            obj["variants"] = obj.variants.filter((j) => {
              if (j) {
                const { variantId = {} } = j;
                let {
                  existencia: existenciaVar = [],
                  continuar_vendiendo = false,
                  sales_channels = {},
                } = variantId || {};
                const { online_store = false } = sales_channels;

                existenciaVar = existenciaVar.filter(
                  (i) => i.almacen_id === Number(almacenId)
                );

                let ex = existenciaVar.reduce(
                  (prev, current) => prev + current.existencia,
                  0
                );

                ex = ex > 0;
                return online_store && (ex || continuar_vendiendo);
              } else {
                return false;
              }
            });
            if (obj["variants"].length === 0) return null;
            const optionValues = [];

            obj.variants.forEach((variant) => {
              Array.from({ length: 10 }, (_, index) => index + 1).forEach(
                (value) => {
                  if (variant.hasOwnProperty(`option${value}`)) {
                    optionValues.push(variant[`option${value}`]);
                  }
                }
              );
            });

            const newOptions = obj.options.map((opt) => ({
              ...opt,
              values: opt.values.filter((i) => optionValues.includes(i)),
            }));
            obj["options"] = newOptions;
          }

          setArticuloDB(obj);
          createImagesGroup(obj?.imagen || []);
        })
        .finally(() => {
          setIsLoading(false);
        });
      ApiServiceComercios.obtenerPedidoDatos().then(
        ({
          ok,
          cliente,
          descuentos,
          impuesto,
          isActivePayment,
          usePaymentsInOrders,
        }) => {
          if (ok) {
            let currency = cliente.moneda;

            let shippingFees = getShippingFees(
              cliente?.envio?.shippingFees,
              currency
            );

            const { productsDiscount, priceToUse } = getDiscounts(
              descuentos,
              cliente
            );

            setCliente(cliente);
            dispatch(
              addClientInfo({
                limiteCredito: cliente?.limite_credito,
                clienteEnvio: cliente?.envio,
                impuesto,
                moneda: currency,
                customerData: cliente,
                shippingFees,
                discounts: productsDiscount,
                priceToUse,
                isActivePayment,
                vendedor: cliente?.vendedor,
                status_microsip: cliente?.status_microsip,
                usePaymentsInOrders,
              })
            );
          }
        }
      );
    }

    function createImagesGroup(img = []) {
      setImages(img);
    }

    fetchArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, almacenId, dispatch]);

  useEffect(() => {
    ApiServiceComercios.obtenerPreferenciasComercio().then(
      ({ preferencia }) => {
        setCanShowStock(preferencia?.mostrarExistenciaProducto);
      }
    );
  }, []);

  function onChangeVariant(imgs = [], existencias) {
    setExistencias(existencias);
    setImages(imgs);
  }

  function previousPage() {
    const prevId = pageState[pageState.indexOf(id) - 1];
    router.push(`/cliente/articulos/${prevId}`, {
      almacenId,
      discounts,
      showTax,
    });
  }

  function nextPage() {
    const nextId = pageState[pageState.indexOf(id) + 1];
    router.push(`/cliente/articulos/${nextId}`, {
      almacenId,
      discounts,
      showTax,
    });
  }

  if (isLoading) {
    return (
      <Layer title={`Artículo`} forbidden={props.forbidden}>
        <ComercioDetalleSkeleton />
      </Layer>
    );
  }

  if (articuloDB === null) {
    return (
      <Layer title={`Artículo`} forbidden={props.forbidden}>
        <EmptyState
          heading="Oops! Hubo un problema al intentar obtener este artículo"
          action={{
            content: "Volver a intentar",
            onAction: () => window.location.reload(),
          }}
          secondaryAction={{
            content: "Regresar a artículos",
            onAction: () => router.goBack(),
          }}
          image="/Ad.svg"
        >
          <p>Intenta de nuevo más tarde</p>
        </EmptyState>
      </Layer>
    );
  }

  return (
    <Layer title="Artículos detalle" forbidden={props.forbidden}>
      <Page
        fullWidth
        breadcrumbs={[
          { content: "Artículos", onAction: () => router.goBack() },
        ]}
        pagination={{
          hasPrevious:
            pageState.indexOf(id) !== 0 ||
            pagination.currentPage * pagination.sizePerPage !== 0,
          onPrevious: previousPage,
          nextTooltip: "Siguiente artículo",
          hasNext: pageState.indexOf(id) + 1 < pageState.length,
          onNext: nextPage,
          previousTooltip: "Artículo anterior",
        }}
      >
        {cartActive && (
          <ShoppingCart
            isCartVisible={cartActive}
            onCloseCart={toggleCartActive}
            currency={cliente?.moneda}
            currencies={currencies}
            modeView="dashboard"
          />
        )}
        <Row className="mb-8 mt-10">
          <Col span={22} className="flex">
            <Space size="small">
              <DisplayText size="large">
                {articuloDB.isParent
                  ? articuloDB?.clave.split(" ")[0]
                  : articuloDB?.clave || ""}
              </DisplayText>
              <Badge status="success">
                {existencias
                  .filter((i) => Number(i.almacen_id) === Number(almacenId))
                  .reduce((total, current) => total + current.existencia, 0) > 0
                  ? "Disponible"
                  : "Bajo pedido"}
              </Badge>
            </Space>
          </Col>
          <Col
            span={2}
            className="flex justify-end pr-8"
            style={{ display: "flex" }}
          >
            <ShoppingCardButton
              badge={allArticles}
              onClick={toggleCartActive}
            />
          </Col>
        </Row>
        <Layout>
          <Layout.Section oneHalf>
            <Card sectioned>
              <CustomCarousel img={images} />
            </Card>
          </Layout.Section>
          <Layout.Section oneHalf>
            <ItemDetail
              itemData={articuloDB}
              almacenId={almacenId}
              discounts={discounts}
              showTax={showTax}
              onChangeVariant={onChangeVariant}
              quantityError={quantityError.length}
              setQuantityError={(value, id) =>
                setQuantityError((prevState) =>
                  value
                    ? prevState.some((errorId) => errorId === id)
                      ? prevState
                      : [...prevState, id]
                    : prevState.filter((errorId) => errorId !== id)
                )
              }
              canShowStock={canShowStock}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </Layer>
  );
}
