import { Button, Checkbox } from "@shopify/polaris";
import { CircleDownMajor } from "@shopify/polaris-icons";
import React, { useEffect, useRef, useState } from "react";
import ImageZoom from "react-medium-image-zoom";
import ReactPlayer from "react-player";
import "./ZoomImageStyles.css";

export default function ZoomImage({
  images = [],
  videos = [],
  children,
  handleSelectImage = () => {},
  selectedImages = [],
  setSelectedFiles = () => {},
  selectedFiles = [],
  version = 1,
}) {
  function RenderZoomImage({ img }) {
    const imageZoomRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
      const handleResize = () => setHeight(imageZoomRef.current.offsetWidth);

      handleResize();

      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
    }, [imageZoomRef]);

    function handleSelect(value, image) {
      handleSelectImage(value, image);
    }

    return (
      <div
        ref={imageZoomRef}
        className={`relative border-2 rounded flex overflow-hidden ${
          selectedImages.some((image) => image === img)
            ? "border-purple-400"
            : "border-gray-400"
        }`}
        style={{ height }}
      >
        <ImageZoom
          onZoom={() => {
            setTimeout(() => {
              let zoomImage = document.querySelector(".zoom-img");
              if (zoomImage) {
                let zoomImageParent = zoomImage.parentElement;
                zoomImageParent.classList = ["img-zoom-container"];
              }
            }, 50);
          }}
          image={{
            src: version === 1 ? img : img.url,
            alt: "",
            className: "static-image self-center",
          }}
          zoomImage={{
            src: version === 1 ? img : img.url,
            className: "zoom-img",
          }}
        />
        <div className="absolute p-2 top-0 right-0 zoom-checkbox">
          <Button
            external
            url={version === 1 ? img : img.url}
            icon={CircleDownMajor}
            plain
          />
        </div>
        <div className="absolute p-2 top-0 left-0 zoom-checkbox">
          {version === 1 ? (
            <Checkbox
              checked={selectedImages.some((image) => image === img)}
              onChange={(value) => handleSelect(value, img)}
            />
          ) : (
            <Checkbox
              onChange={(val) => {
                if (val) {
                  setSelectedFiles((prevState) => [
                    ...new Set([...prevState, img.url]),
                  ]);
                } else {
                  setSelectedFiles((prevState) =>
                    prevState.filter((url) => url !== img.url)
                  );
                }
              }}
              checked={selectedFiles.some((image) => image === img.url)}
            />
          )}
        </div>
      </div>
    );
  }

  function RenderVideo({ vid }) {
    const player = useRef(null);

    function handleSelect(value, image) {
      handleSelectImage(value, image);
    }

    return (
      <div
        className={`relative border-2 rounded flex overflow-hidden ${
          selectedImages.some((image) => image === vid)
            ? "border-purple-400"
            : "border-gray-400"
        }`}
      >
        <ReactPlayer
          ref={player}
          url={version === 1 ? vid : vid.url}
          height={100}
          width={100}
          controls
          style={{ aspectRatio: "1/1" }}
        />

        <div className="absolute p-2 top-0 right-0 zoom-checkbox">
          <Button
            external
            url={version === 1 ? vid : vid.url}
            icon={CircleDownMajor}
            plain
          />
        </div>
        <div className="absolute p-2 top-0 left-0 zoom-checkbox">
          {version === 1 ? (
            <Checkbox
              checked={selectedImages.some((image) => image === vid)}
              onChange={(value) => handleSelect(value, vid)}
            />
          ) : (
            <Checkbox
              onChange={(val) => {
                if (val) {
                  setSelectedFiles((prevState) => [
                    ...new Set([...prevState, vid.url]),
                  ]);
                } else {
                  setSelectedFiles((prevState) =>
                    prevState.filter((url) => url !== vid.url)
                  );
                }
              }}
              checked={selectedFiles.some((image) => image === vid.url)}
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={images.length > 0 ? "zoomContainer" : "zoomContainerDefault"}
    >
      {images.map((img, index) => (
        <RenderZoomImage key={index} img={img} />
      ))}
      {videos.map((vid, index) => (
        <RenderVideo key={index} vid={vid} />
      ))}
      {children}
    </div>
  );
}
