import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Page,
  Layout,
  Card,
  TextStyle,
  Link,
  ContextualSaveBar,
  TextField,
  Button,
  ButtonGroup,
  FormLayout,
  Select,
  Autocomplete,
  Icon,
  Tag,
} from "@shopify/polaris";
import { SearchMinor } from "@shopify/polaris-icons";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  obtenerComercioConfigMicrosip,
  editarComercioConfigMicrosip,
  editarComercio,
} from "../../actions/ComerciosActions";
import {
  Layer,
  TestConnectionModal,
  ComercioMicrosipSkeleton,
  HelpFooter,
  MicrosipConfigModal,
} from "../../components";
import { EmailEx } from "../../utils/RegEx";
import { toggleToast } from "../../actions/InteractiveActions";
import { isNull } from "lodash";
import useDebounce from "../../services/useDebounce";
import ApiServiceComercios from "../../services/ApiServiceComercios";

export default function ComercioInteMicrosipScreen({ forbidden }) {
  const router = useHistory();
  const dispatch = useDispatch();
  const queryRef = useRef(null);

  const [active, setActive] = useState(false);
  const [isActiveConfig, setIsActiveConfig] = useState(false);
  const defaultValues = useRef({
    id: "",
    host: "",
    datos: "",
    empresa: "",
    usuario: "",
    password: "",
    email: "",
    folio: "",
    folioCotizacion: "",
    articuloFlete: {},
    articuloDatos: {},
  });
  const [configuracion, setConfiguracion] = useState({
    ...defaultValues.current,
  });
  const [isDirty, setIsDirty] = useState(false);
  const [fieldsError, setFieldsError] = useState({ email: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccessConnection, setIsSuccessConnection] = useState(null);
  const [series, setSeries] = useState([]);
  const [seriesCot, setSeriesCot] = useState([]);
  const [isLoadingArticulos, setIsLoadingArticulos] = useState(false);
  const [articulosOptions, setArticulosOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValueArticle, setInputValueArticle] = useState("");

  const debouncedSearchTerm = useDebounce(inputValueArticle, 900);

  const handleChange = useCallback(() => setActive(!active), [active]);
  const handleChangeConfig = useCallback(
    () => setIsActiveConfig(!isActiveConfig),
    [isActiveConfig]
  );
  const handleFieldChange = useCallback((value, key) => {
    setConfiguracion((state) => ({ ...state, [key]: value }));
    value && setIsDirty(true);
  }, []);

  const updateTextArticle = useCallback((value) => {
    setInputValueArticle(value);
    if (value === "") {
      setArticulosOptions([]);
      return;
    }

    const filterRegex = new RegExp(value, "i");
    const resultOptions = [].filter((option) =>
      option.label.match(filterRegex)
    );
    setArticulosOptions(resultOptions);
  }, []);

  useEffect(() => {
    queryRef.current = inputValueArticle;
  });

  useEffect(() => {
    function onQueryChange() {
      setIsLoadingArticulos(true);
      ApiServiceComercios.obtenerArticulos({
        sort: "nombre",
        query: queryRef.current,
        limit: 10,
        skip: 0,
        existencia: -1,
        onlyName: true,
      })
        .then(({ articulos }) => {
          const obj = articulos.map((i) => ({
            label: i.nombre,
            value: i._id,
          }));
          setArticulosOptions(obj);
        })
        .finally(() => setIsLoadingArticulos(false));
    }

    onQueryChange();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    dispatch(obtenerComercioConfigMicrosip())
      .then((response) => {
        const {
          datos,
          empresa,
          host,
          password,
          usuario,
          _id,
          email,
          folio,
          articuloFlete,
          articuloDatos,
        } = response;
        const data = {
          host,
          datos,
          articuloFlete,
          folio,
          empresa: empresa.split(".")[0],
          usuario,
          password,
          email,
          id: _id,
          articuloDatos,
        };
        setConfiguracion((state) => ({ ...state, ...data }));
      })
      .finally(() => setIsLoading(false));

    ApiServiceComercios.obtenerComercioConfigMicrosipSeries().then(
      ({
        series = [],
        folio = "",
        seriesCotizacion = [],
        folioCotizacion = "",
      }) => {
        setSeries(
          series.map(({ serie_ped }) => ({
            label: serie_ped,
            value: serie_ped,
          }))
        );
        setSeriesCot(
          seriesCotizacion.map(({ serie_cot }) => ({
            label: serie_cot,
            value: serie_cot,
          }))
        );
        setConfiguracion((state) => ({
          ...state,
          folio: folio,
          folioCotizacion: folioCotizacion !== "@" ? folioCotizacion : "",
        }));
      }
    );
  }, [dispatch]);

  function handleEmailOnBlur() {
    if (!EmailEx.test(configuracion.email)) {
      setFieldsError((state) => ({
        ...state,
        email: "Ingresa una dirección de correo electrónico válida",
      }));
    } else {
      setFieldsError((state) => ({ ...state, email: "" }));
    }
  }

  function handleDiscard() {
    router.push("/admin/configuracion/integraciones");
  }

  function updateSelection(selected) {
    setSelectedOptions(selected);
    setConfiguracion((state) => ({
      ...state,
      articuloFlete: {
        id: selected[0],
        nombre: articulosOptions.find((i) => i.value === selected[0])["label"],
      },
    }));
    setIsDirty(true);
  }

  function onRemoveFlete() {
    setConfiguracion((state) => ({
      ...state,
      articuloFlete: { id: null, nombre: "" },
    }));
  }

  function handleSave() {
    setIsSaving(true);
    let config = { ...configuracion };

    if (!isNull(isSuccessConnection)) {
      if (isSuccessConnection) {
        dispatch(editarComercio({ comercio: { estatus: 2 } }));
        config["enabled"] = true;
      } else {
        config["enabled"] = false;
      }
    }

    dispatch(editarComercioConfigMicrosip(config, configuracion.id))
      .then(({ message }) => {
        dispatch(toggleToast({ message }));
      })
      .finally(() => {
        setIsSaving(false);
        setIsDirty(false);
      });
  }

  function handleEnableConnection(isSuccess) {
    setIsSuccessConnection(isSuccess);
    handleChange();
  }

  function handleSetArticuloData(data) {
    handleFieldChange(data, "articuloDatos");
    handleChangeConfig();
  }

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Cambios no guardados"
      saveAction={{
        onAction: handleSave,
        disabled: !isDirty || fieldsError.email || isSaving,
        loading: isSaving,
      }}
      discardAction={{
        onAction: handleDiscard,
        disabled: isSaving,
      }}
    />
  ) : null;

  const buscarArticuloField = (
    <Autocomplete.TextField
      onChange={updateTextArticle}
      value={inputValueArticle}
      prefix={<Icon source={SearchMinor} color="inkLighter" />}
      placeholder="Busca un artículo"
    />
  );

  if (isLoading) {
    return (
      <Layer title="Integración microsip" forbidden={forbidden}>
        <ComercioMicrosipSkeleton />
      </Layer>
    );
  }

  return (
    <Layer title="Integración microsip" forbidden={forbidden}>
      <Page
        separator
        title="Microsip"
        breadcrumbs={[
          {
            content: "Integraciones",
            url: "/admin/configuracion/integraciones",
          },
        ]}
      >
        {contextualSaveBarMarkup}
        {active && (
          <TestConnectionModal
            isActive={active}
            connection={configuracion}
            handleTestConnection={handleEnableConnection}
          />
        )}
        {isActiveConfig && (
          <MicrosipConfigModal
            isOpen={isActiveConfig}
            handleChange={handleChangeConfig}
            data={configuracion.articuloDatos}
            handleSave={handleSetArticuloData}
          />
        )}
        <Layout>
          <Layout.AnnotatedSection
            title="Detalles de la integración"
            description={
              <TextStyle>
                B2BGO usarán estos datos para conectarse con Microsip. <br />
                <br />
                En el servidor de Microsip deberás instalar 4Q Sinc de Exsim 
                para completar la integración, descárga aquí la versión para <Link url="https://exsim.mx/downloads/4QSinc_2022.zip">Microsip 2022</Link> o <Link url="https://exsim.mx/downloads/4QSinc_2023.zip">Microsip 2023</Link>.
              </TextStyle>
            }
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextField
                    label="Servidor"
                    type="text"
                    placeholder="Nombre del host o dirección ip"
                    helpText="Indica el nombre de la computadora donde está instalado el Servidor de la base de datos de la conexión remota."
                    value={configuracion.host}
                    onChange={(text) => handleFieldChange(text, "host")}
                  />
                  <TextField
                    label="Carpeta de datos"
                    type="text"
                    placeholder={`C:\\Microsip datos\\`}
                    helpText="Escribe la ubicación, en la computadora que tiene el Servidor de la base de datos, donde se guardan los datos de las empresas. Debe ser un nombre de directorio válido para Windows."
                    value={configuracion.datos}
                    onChange={(text) => handleFieldChange(text, "datos")}
                  />
                  <TextField
                    label="Base de datos"
                    type="text"
                    helpText="Escribe el nombre de la empresa a conectar."
                    suffix=".FDB"
                    value={configuracion.empresa}
                    onChange={(text) => handleFieldChange(text, "empresa")}
                  />
                  <TextField
                    label="Nombre de usuario"
                    type="text"
                    helpText="Escribe tu nombre de usuario Microsip."
                    value={configuracion.usuario}
                    onChange={(text) => handleFieldChange(text, "usuario")}
                  />
                  <TextField
                    label="Contraseña"
                    type="password"
                    helpText="Escribe tu contraseña del usuario Microsip. Toma en cuenta las letras mayúsculas y minúsculas."
                    value={configuracion.password}
                    onChange={(text) => handleFieldChange(text, "password")}
                  />
                  <div className="flex justify-end">
                    <Button
                      onClick={() =>
                        router.push(
                          "/admin/configuracion/integracion/microsip/datos"
                        )
                      }
                    >
                      Configuración
                    </Button>
                    <div className="mx-2" />
                    <Button onClick={handleChange}>Probar conexión</Button>
                  </div>
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Folio de las cotizaciones"
            description="Selecciona la serie de las cotizaciones Microsip."
          >
            <Card>
              <Card.Section>
                <Select
                  label="Serie"
                  options={seriesCot}
                  value={configuracion.folioCotizacion}
                  onChange={(text) =>
                    handleFieldChange(text, "folioCotizacion")
                  }
                  placeholder="Selecciona una serie de cotización"
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Folio del pedido"
            description="Selecciona la serie de los pedidos Microsip."
          >
            <Card>
              <Card.Section>
                <Select
                  label="Serie"
                  options={series}
                  value={configuracion.folio}
                  onChange={(text) => handleFieldChange(text, "folio")}
                  placeholder="Selecciona una serie de pedido"
                />
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Artículo de fletes"
            description="Si cobras fletes en los pedidos, selecciona el artículo que corresponde a los fletes."
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextStyle>Artículo de fletes</TextStyle>
                  <Autocomplete
                    listTitle="Artículos disponibles"
                    willLoadMoreResults
                    loading={isLoadingArticulos}
                    options={articulosOptions}
                    selected={selectedOptions}
                    onSelect={updateSelection}
                    textField={buscarArticuloField}
                    emptyState="No hay artículos"
                  />
                  {configuracion.articuloFlete.nombre && (
                    <Tag onRemove={onRemoveFlete}>
                      {configuracion.articuloFlete.nombre}
                    </Tag>
                  )}
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection
            title="Notificaciones"
            description="Estas notificaciones pueden ayudarte a hacer un seguimiento de tu integración y mantenerte informado sobre los cambios que afecten tu integración con Microsip."
          >
            <Card>
              <Card.Section>
                <FormLayout>
                  <TextField
                    label="Correo electrónico del desarrollador de emergencia"
                    type="text"
                    error={fieldsError.email}
                    placeholder="contacto@email.com"
                    value={configuracion.email}
                    onBlur={handleEmailOnBlur}
                    onChange={(text) => handleFieldChange(text, "email")}
                  />
                </FormLayout>
              </Card.Section>
            </Card>
          </Layout.AnnotatedSection>
          <Layout.AnnotatedSection>
            <div className="flex justify-end">
              <ButtonGroup>
                <Button onClick={handleDiscard} disabled={isSaving}>
                  Descartar
                </Button>
                <Button
                  disabled={fieldsError.email || isSaving}
                  loading={isSaving}
                  primary
                  onClick={handleSave}
                >
                  Guardar
                </Button>
              </ButtonGroup>
            </div>
          </Layout.AnnotatedSection>
        </Layout>
      </Page>
      <HelpFooter
        title="microsip"
        url="https://help.b2bgo.mx/configuracion/integraciones/microsip"
      />
    </Layer>
  );
}
