import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import {
  ActionList,
  Card,
  Frame,
  TopBar,
  Loading,
  Toast,
  Spinner,
  Banner,
  TextStyle,
} from "@shopify/polaris";
import {
  CircleRightMajor,
  OnlineStoreMajor,
  QuestionMarkMajor,
} from "@shopify/polaris-icons";
import jwt from "jsonwebtoken";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../context/SocketContext";
import { clearAll, logout } from "../../actions/AuthActions";
import { toggleToast, updateVisitors } from "../../actions/InteractiveActions";
import {
  getUserInfo,
  getToast,
  getChat,
  getAuthData,
  getNombreComercio,
  getIsLoadingProgressBar,
} from "../../reducers";
import useDebounce from "../../services/useDebounce";
import ApiServiceComercios from "../../services/ApiServiceComercios";
import Sidebar from "../Sidebar";
import ForbiddenLayout from "../ForbiddenLayout";
import "./LayoutStyles.css";
import {
  chatIntegration,
  validateLogo,
  validateUrl,
} from "../../utils/validators";
import useToggle from "../../hooks/useToggle";
import { Colors } from "../../styles";
import { getContrastYIQ } from "../../utils/getContrast";
import useAbility from "../../hooks/useAbility";
import { scrollToTop as scrollTop } from "../../utils/windowActions";
import useSchedule from "../../hooks/useSchedule";
import NavbarNotifications, {
  SecondaryMenuMarkup,
} from "./NavbarNotifications";

const LayoutLight = React.lazy(() => import("./LayoutLight"));
const LayoutDark = React.lazy(() => import("./LayoutDark"));

export default function Layer({
  children,
  title,
  forbidden,
  scrollToTop = false,
}) {
  const router = useHistory();
  const dispatch = useDispatch();
  const queryValueCommerceRef = useRef(null);
  const queryValueClientRef = useRef(null);
  const socketRef = useRef(null);
  const url_imagen = useSelector((state) => state.Auth.url_imagen);

  const { socket } = useContext(SocketContext);

  const chat = useSelector(getChat);
  const toggle = useSelector(getToast);
  const usuario = useSelector(getUserInfo);
  const nombre_comercio = useSelector(getNombreComercio);
  const isLoadingProgressBar = useSelector(getIsLoadingProgressBar);
  let { access_token: token, grant_type } = useSelector(getAuthData);
  const colorSchema = useSelector(
    (state) => state.Auth.comercioData.colorSchema
  );

  const [searchValueCliente, setSearchValueCliente] = useState("");
  const [searchValueComercio, setSearchValueComercio] = useState("");
  const [isUserMenuOpen, toggleIsUserMenuOpen] = useToggle(false);
  const [searchActive, setSearchActive] = useState(false);
  const [mobileNavigationActive, toggleMobileNavigationActive] =
    useToggle(false);
  const [isLogginOut, setIsLogginOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState({
    Pedidos: [],
    Artículos: [],
    Clientes: [],
    Descuentos: [],
    Anuncios: [],
    Cotizaciones: [],
    Oportunidades: [],
  });
  const [notifications, setNotifications] = useState();
  const primaryColor = colorSchema?.primary || null;
  const isForbiddenReadPedidos = useAbility("read", "Pedidos");
  const isForbiddenReadCotizaciones = useAbility("read", "Cotizaciones");
  const isForbiddenReadArticulos = useAbility("read", "Artículos");
  const isForbiddenReadOportunidades = useAbility("read", "Oportunidades");
  const isForbiddenOnlineStore = useAbility("read", "Tienda en línea");
  const isForbiddenReadClientes = useAbility("read", "Clientes");
  const isForbiddenReadDescuentos = useAbility("read", "Descuentos");
  const isForbiddenReadAnuncios = useAbility("read", "Anuncios");
  const [statusMicrosip, setStatusMicrosip] = useState("");

  const { shouldUseSchedule, isInRange, remaining } = useSchedule();

  useEffect(() => {
    if (
      shouldUseSchedule &&
      !isInRange &&
      ["CLIENTE", "CONTACT"].includes(grant_type)
    ) {
      window.location.reload();
    }
  }, [isInRange, grant_type, shouldUseSchedule]);

  // Set title
  useEffect(() => {
    document.title = title || "B2BGO";
    return () => null;
  }, [title]);

  // Scroll to top
  useEffect(() => {
    if (scrollToTop) {
      scrollTop();
    }
  }, [scrollToTop]);

  useEffect(() => {
    queryValueCommerceRef.current = searchValueComercio;
    queryValueClientRef.current = searchValueCliente;
    socketRef.current = socket;
  });

  const debouncedSearchComercio = useDebounce(searchValueComercio, 1100);
  const debouncedSearchCliente = useDebounce(searchValueCliente, 1100);

  const [isSecondaryMenuOpen, toggleIsSecondaryMenuOpen] = useToggle(false);

  useEffect(() => {
    async function verifyToken() {
      if (token) {
        try {
          const decoded = jwt.verify(token, process.env.REACT_APP_SECRET_SEED);

          const { data } = decoded;
          const { id_comercio } = data;

          socket.on("connect-react-visit", (_, callback) => {
            callback({ id: usuario._id, grant_type, id_comercio });
          });

          socket.on("current-visits", (visitors) => {
            dispatch(updateVisitors({ visitors }));
          });
        } catch (error) {}
      } else {
        dispatch(clearAll());
      }
    }

    verifyToken();

    return () => {};
  }, [socket, usuario._id, grant_type, dispatch, token]);

  useEffect(() => {
    async function verifyToken() {
      if (token) {
        try {
          const decoded = jwt.verify(token, process.env.REACT_APP_SECRET_SEED);

          const { data } = decoded;
          const { id_comercio } = data;

          socketRef.current.on("connect-react-view", (_, callback) => {
            callback({ id_comercio });
          });
          socketRef.current.emit("current-views", { id_comercio });
        } catch (error) {}
      } else {
        dispatch(clearAll());
      }
    }

    verifyToken();
  }, [dispatch, token]);

  useEffect(() => {
    const getCustomerStatus = () => {
      ApiServiceComercios.getCustomerStatus().then(({ customer }) => {
        setStatusMicrosip(customer?.status_microsip);
      });
    };
    ["CLIENTE", "CONTACT"].includes(grant_type) && getCustomerStatus();
  }, [grant_type]);

  useEffect(() => {
    function onQueryComercioChange() {
      if (queryValueCommerceRef.current.length > 0) {
        setIsLoading(true);
        ApiServiceComercios.obtenerSearchData({
          query: queryValueCommerceRef.current,
        })
          .then(
            ({
              data: {
                pedidos = [],
                articulos = [],
                clientes = [],
                descuentos = [],
                anuncios = [],
                cotizaciones = [],
                oportunidades = [],
              },
            }) => {
              setDataSearch((state) => ({
                ...state,
                Anuncios: anuncios,
                Artículos: articulos,
                Clientes: clientes,
                Descuentos: descuentos,
                Pedidos: pedidos,
                Cotizaciones: cotizaciones,
                Oportunidades: oportunidades,
              }));
            }
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
    }

    onQueryComercioChange();
  }, [debouncedSearchComercio]);

  useEffect(() => {
    function onQueryClienteChange() {
      if (queryValueClientRef.current.length > 0) {
        setIsLoading(true);
        ApiServiceComercios.obtenerSearchData({
          query: queryValueClientRef.current,
          forClient: true,
        })
          .then(
            ({
              data: { pedidos = [], cotizaciones = [], oportunidades = [] },
            }) => {
              setDataSearch((state) => ({
                ...state,
                Pedidos: pedidos,
                Cotizaciones: cotizaciones,
                Oportunidades: oportunidades,
              }));
            }
          )
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
    onQueryClienteChange();
  }, [debouncedSearchCliente]);

  useEffect(() => {
    validateLogo({
      grant_type,
      nombre_comercio,
      primaryColor: colorSchema.primary,
      url_imagen,
    });
    chatIntegration(chat);
  }, [colorSchema.primary, grant_type, nombre_comercio, url_imagen, chat]);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchActive(false);
  }, []);

  const handleSearchFieldChangeCliente = useCallback((value) => {
    setSearchValueCliente(value);
    setSearchActive(value.length > 0);
  }, []);

  const handleSearchFieldChangeComercio = useCallback((value) => {
    setSearchValueComercio(value);
    setSearchActive(value.length > 0);
  }, []);

  function toggleActive() {
    dispatch(toggleToast());
  }

  let userActions = [
    {
      content: "Cerrar sesión",
      icon: CircleRightMajor,
      onAction: () => {
        dispatch(toggleToast({ message: "" }));
        setIsLogginOut(true);
        dispatch(logout(usuario._id)).then((res) => {
          router.replace("/login");
        });
      },
    },
  ];

  if (
    ["COMERCIO", "AGENT"].includes(grant_type) &&
    !isForbiddenReadOportunidades
  ) {
    userActions.unshift({
      content: "Centro de ayuda",
      icon: QuestionMarkMajor,
      onAction: () => router.push("/admin/centro-de-ayuda"),
    });
  }

  if (["CLIENTE", "CONTACT"].includes(grant_type) && !isForbiddenOnlineStore) {
    userActions.unshift({
      content: "Ir a tienda en línea",
      icon: OnlineStoreMajor,
      url: "/",
      disabled: ["B", "V"].includes(statusMicrosip),
    });
  }

  const userMenuMarkup = (
    <TopBar.UserMenu
      name={`${usuario.nombre ? usuario.nombre : ""} ${
        usuario.name ? usuario.name : ""
      } ${usuario.last_name ? usuario.last_name : ""}`}
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
      initials={`${
        usuario.nombre
          ? usuario.nombre
              .match(/\b(\w)/g)
              .join("")
              .slice(0, 2)
          : ""
      }${usuario.name ? usuario.name[0].toUpperCase() : ""}${
        usuario.last_name ? usuario.last_name[0].toUpperCase() : ""
      }`}
      actions={[
        {
          items: userActions,
        },
      ]}
    />
  );

  const searchResultsMarkup = (
    <Card>
      {isLoading ? (
        <div className="flex flex-row items-center justify-center my-8">
          <Spinner size="large" color="teal" />
        </div>
      ) : (
        <div className="searchScroll">
          <ActionList
            sections={[
              {
                title: `${!isForbiddenReadCotizaciones ? "Cotizaciones" : ""}`,
                items: !isForbiddenReadCotizaciones
                  ? dataSearch.Cotizaciones.map(({ folio, _id }) => ({
                      content: folio,
                      url: `/${
                        ["COMERCIO", "AGENT"].includes(grant_type)
                          ? "admin"
                          : "cliente"
                      }/cotizaciones/${_id}`,
                    }))
                  : [],
              },
              {
                title: `${!isForbiddenReadPedidos ? "Pedidos" : ""}`,
                items: !isForbiddenReadPedidos
                  ? dataSearch.Pedidos.map(({ folio, _id }) => ({
                      content: folio,
                      url: `/${
                        ["COMERCIO", "AGENT"].includes(grant_type)
                          ? "admin"
                          : "cliente"
                      }/pedidos/${_id}`,
                    }))
                  : [],
              },
              {
                title: `${
                  !isForbiddenReadOportunidades ? "Oportunidades" : ""
                }`,
                items: !isForbiddenReadOportunidades
                  ? dataSearch.Oportunidades.map(({ folio, _id }) => ({
                      content: folio,
                      url: `/admin/oportunidades/${_id}`,
                    }))
                  : [],
              },
              {
                title: `${!isForbiddenReadArticulos ? "Artículos" : ""}`,
                items: !isForbiddenReadArticulos
                  ? dataSearch.Artículos.map(({ nombre, _id }) => ({
                      content: nombre,
                      url: `/admin/articulos/${_id}`,
                    }))
                  : [],
              },
              {
                title: `${!isForbiddenReadClientes ? "Clientes" : ""}`,
                items: !isForbiddenReadClientes
                  ? dataSearch.Clientes.map(({ nombre, _id }) => ({
                      content: nombre,
                      url: `/admin/clientes/${_id}`,
                    }))
                  : [],
              },
              {
                title: `${!isForbiddenReadDescuentos ? "Descuentos" : ""}`,
                items: !isForbiddenReadDescuentos
                  ? dataSearch.Descuentos.map(({ titulo, _id, tipo }) => ({
                      content: titulo,
                      url: validateUrl(tipo.tipo, _id),
                    }))
                  : [],
              },
              {
                title: `${!isForbiddenReadAnuncios ? "Anuncios" : ""}`,
                items: !isForbiddenReadAnuncios
                  ? dataSearch.Anuncios.map(({ titulo, _id }) => ({
                      content: titulo,
                      url: `/admin/anuncios/${_id}`,
                    }))
                  : [],
              },
            ]}
          />
        </div>
      )}
    </Card>
  );

  const searchFieldMarkup = ["COMERCIO", "AGENT"].includes(grant_type) ? (
    <TopBar.SearchField
      showFocusBorder
      onChange={handleSearchFieldChangeComercio}
      value={searchValueComercio}
      placeholder="Buscar"
    />
  ) : (
    <TopBar.SearchField
      showFocusBorder
      onChange={handleSearchFieldChangeCliente}
      value={searchValueCliente}
      placeholder="Buscar"
    />
  );

  const topBarMarkup = (
    <TopBar
      searchResultsOverlayVisible
      showNavigationToggle
      userMenu={userMenuMarkup}
      secondaryMenu={
        ["COMERCIO", "AGENT"].includes(grant_type) ? (
          <SecondaryMenuMarkup
            notifications={notifications?.length || 0}
            isSecondaryMenuOpen={isSecondaryMenuOpen}
            toggleIsSecondaryMenuOpen={toggleIsSecondaryMenuOpen}
          />
        ) : null
      }
      searchResultsVisible={searchActive}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  const loadingMarkup =
    isLogginOut || isLoadingProgressBar ? <Loading /> : null;

  const toastMarkup = toggle.activeToast ? (
    <Toast duration={1500} content={toggle.message} onDismiss={toggleActive} />
  ) : null;

  return (
    <div className="relative">
      <Frame
        topBar={topBarMarkup}
        navigation={<Sidebar />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {shouldUseSchedule && ["CLIENTE", "CONTACT"].includes(grant_type) && (
          <div className="m-6">
            {isInRange && remaining.minutes < 5 && remaining.seconds <= 59 && (
              <Banner
                title={`La sesión finalizará en ${String(
                  remaining.minutes
                ).padStart(2, "0")}:${String(remaining.seconds).padStart(
                  2,
                  "0"
                )}`}
                status="warning"
              >
                <TextStyle>
                  El horario disponible está por finalizar, por tu seguridad
                  guarda los documentos que estás realizando.
                </TextStyle>
              </Banner>
            )}
          </div>
        )}

        {forbidden && <ForbiddenLayout />}
        {!forbidden && children}
        {loadingMarkup}
        {toastMarkup}

        <React.Suspense fallback={<></>}>
          {getContrastYIQ(
            ["CLIENTE", "CONTACT"].includes(grant_type) && primaryColor
              ? primaryColor
              : Colors.main
          ) === "light" ? (
            <LayoutLight />
          ) : (
            <LayoutDark />
          )}
        </React.Suspense>
      </Frame>

      <NavbarNotifications
        token={token}
        notifications={notifications}
        setNotifications={setNotifications}
        isSecondaryMenuOpen={isSecondaryMenuOpen}
        toggleIsSecondaryMenuOpen={toggleIsSecondaryMenuOpen}
      />
    </div>
  );
}
